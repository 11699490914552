import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from '../Table.module.scss';

import { getTutorAppointments } from '../../../actions/appointments';
import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner';
import { endOfDay, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';
import Row from './Row';
import Header from './Header';
import { appointmentStatus } from '../../../domains/appointments/status';
import SuccessToast from '../../design-system/toast/successToast/SuccessToast';
import { cleanChangeAppointmentStatus } from '../../../actions/appointments';

const columns = {
  classTime: 'Session time',
  joinClassroom: 'Join session',
  students: 'Learner',
  classMaterial: 'Resources',
  classReport: 'Coach attendance',
  reportStudentOutcome: 'Learner attendance'
};

const AppointmentsToday = ({
  errorGettingTutorAppointments,
  getTutorAppointmentsAction,
  isGettingTutorAppointments,
  tutorAppointments,
  isChangingAppointmentStatusSuccess,
  isRegisteringTutorAttendanceSuccess,
  cleanChangeAppointmentStatusAction,
  errorChangingAppointmentStatus,
  user
}) => {
  useEffect(() => {
    getTutorAppointments();
  }, []);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getTutorAppointments = () => {
    getTutorAppointmentsAction({
      token: user?.token,
      tutorId: user?.id,
      startDate: zonedTimeToUtc(startOfDay(new Date()), timezone).toISOString(),
      endDate: zonedTimeToUtc(endOfDay(new Date()), timezone).toISOString()
    });
  };

  if (isGettingTutorAppointments)
    return (
      <div>
        <LoadingSpinner />
      </div>
    );

  return (
    <div className={styles['table-container']}>
      <table>
        <thead>
          <Header columns={columns} />
        </thead>
        <tbody>
          {tutorAppointments?.map((appt, index) => {
            return appt.status !== appointmentStatus.canceled ? <Row appointment={appt} key={index} timezone={timezone} user={user} /> : null;
          })}
        </tbody>
      </table>
      <ErrorToast
        errorMsg="Something went wrong while getting your appointments. Please refresh the page and try again."
        show={errorGettingTutorAppointments}
      />
      <SuccessToast msg="Your attendance was successfully registered. Enjoy the session!" show={isRegisteringTutorAttendanceSuccess} />
      <SuccessToast
        msg="Successfully updated your Live Practice status."
        show={isChangingAppointmentStatusSuccess}
        onClose={cleanChangeAppointmentStatusAction}
      />
      <ErrorToast
        errorMsg="Something went wrong while setting this class status. Please refresh the page and try again."
        show={errorChangingAppointmentStatus}
        onClose={cleanChangeAppointmentStatusAction}
      />
    </div>
  );
};

AppointmentsToday.propTypes = {
  errorGettingTutorAppointments: PropTypes.bool,
  errorChangingAppointmentStatus: PropTypes.bool,
  isChangingAppointmentStatusSuccess: PropTypes.bool,
  isRegisteringTutorAttendanceSuccess: PropTypes.bool,
  isGettingTutorAppointments: PropTypes.bool,
  cleanChangeAppointmentStatusAction: PropTypes.func.isRequired,
  getTutorAppointmentsAction: PropTypes.func.isRequired,
  tutorAppointments: PropTypes.array,
  user: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    errorChangingAppointmentStatus: state.appointments.errorChangingAppointmentStatus,
    cleanChangeAppointmentStatusAction: cleanChangeAppointmentStatus,
    isRegisteringTutorAttendanceSuccess: state.appointments.isRegisteringTutorAttendanceSuccess,
    isChangingAppointmentStatusSuccess: state.appointments.isChangingAppointmentStatusSuccess,
    errorGettingTutorAppointments: state.appointments.errorGettingTutorAppointments,
    isGettingTutorAppointments: state.appointments.isGettingTutorAppointments,
    tutorAppointments: state.appointments.tutorAppointments,
    user: state.user
  };
};

export default connect(mapStateToProps, {
  getTutorAppointmentsAction: getTutorAppointments
})(AppointmentsToday);
