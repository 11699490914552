import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
import { Prompt } from 'react-router-dom';

import Button from '../design-system/button/Button';
import { capitalize } from '../../utils/string-helpers';
import ProfilePhoto from '../uikit/ProfilePhoto';
import Timetable from '../uikit/Timetable.js';
import styles from '../../style/components/tutor-match/TutorMatchProfile.module.scss';

import AutoSuggestTags from './AutoSuggestTags';
import { cleanPreviousState, openBookingModal } from '../../actions/new-booking';
import { setOpenChangeCoachModal } from '../../actions/change-coach';
import ChangeCoachModal from '../../components/new-booking/components/changingCoach/ChangeCoachModal';
import { getSessionsStatus } from '../../actions/sessions';
import BookingTuitionSessionModal from '../../components/new-booking/components/booking/BookingTuitionSessionModal';

import IconQuote from '../../assets/icons/icon_quote.svg';
import ProfilePlaceholder from '../../assets/people/profile-placeholder.png';
import CheckboxField from '../design-system/checkbox-field/CheckboxField';
import ErrorToast from '../design-system/toast/errorToast/ErrorToast';
import SuccessToast from '../design-system/toast/successToast/SuccessToast';
import RegularFormField from '../design-system/regular-field/RegularFormField';
import { ReactComponent as BriefcaseIcon } from '../../assets/icons/icon_work-suitcase.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/icon_play.svg';
import { ReactComponent as MicrophoneIcon } from '../../assets/icons/icon_microphone.svg';
import { ReactComponent as PersonIcon } from '../../assets/icons/icon_person.svg';
import Dropzone from '../design-system/dropzone';

class TutorMatchProfile extends Component {
  state = {
    isEditing: false,
    consent: false,
    bio: this.props.bio,
    professionalInterests: this.props.professionalInterests,
    personalInterests: this.props.personalInterests,
    textOtherProfessional: this.props.textOtherProfessional,
    textOtherPersonal: this.props.textOtherPersonal,
    professionalTag: '',
    personalTag: '',
    unselectedProfessionalInterests: [],
    unselectedPersonalInterests: [],
    shouldShowOnDropRejected: false,
    shouldShowOnUpdateError: false,
    shouldShowOnUpdateSuccess: false,
    shouldShowMinTagError: false
  };

  componentDidMount() {
    if (this.props.token && this.props.assignedCourse) this.getSessionsStatus();

    if (!this.props.checkProfile && this.props.userType !== 'student') {
      this.fetchUnselectedTags();
    }
  }

  componentDidUpdate(prevProps) {
    const assignedCourseChanged = this.props.assignedCourse && this.props.assignedCourse !== prevProps.assignedCourse;
    if (assignedCourseChanged) this.getSessionsStatus();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.didTutorEdited);
  }

  showOnDropRejected = () => {
    this.setState({ shouldShowOnDropRejected: true });
  };

  closeOnDropRejected = () => {
    this.setState({ shouldShowOnDropRejected: false });
  };

  onDrop = (files) => {
    const file = files.pop(0);
    this.setState({
      updatedPhoto: file
    });

    files.forEach((aFile) => {
      window.URL.revokeObjectURL(aFile.preview);
    });
  };

  onProfessionalValueChange = (tag) => {
    this.setState({ professionalTag: tag });
  };

  onPersonalValueChange = (tag) => {
    this.setState({ personalTag: tag });
  };

  getSessionsStatus() {
    if (this.props.assignedCourse && this.props.assignedCourse.id) {
      const courseId = this.props.assignedCourse.id;
      this.props.getSessionsStatusAction(this.props.token, courseId);
    }
  }

  getLanguageDisplay = (language) => `${language.language}`;

  getPersonalSuggestions = (value) => {
    const inputValue = value !== undefined ? value.trim().toLowerCase() : '';
    const inputLength = inputValue.length;
    let renderedItems = 0;

    const unselectedPersonalInterests = this.state.unselectedPersonalInterests.filter((item) => {
      if (item.selected) {
        return false;
      }
      if (renderedItems < 5) {
        let renderItem;
        if (value === '' || inputLength === 0) {
          renderItem = true;
        } else {
          renderItem = item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        }
        if (renderItem) {
          renderedItems += 1;
          return renderItem;
        }
      }
      return false;
    });
    this.setState({ personalValues: unselectedPersonalInterests });
  };

  getProfessionalSuggestions = (value) => {
    const inputValue = value !== undefined ? value.trim().toLowerCase() : '';
    const inputLength = inputValue.length;
    let renderedItems = 0;

    const unselectedPersonalInterests = this.state.unselectedProfessionalInterests.filter((item) => {
      if (item.selected) {
        return false;
      }
      if (renderedItems < 5) {
        let renderItem;
        if (value === '' || inputLength === 0) {
          renderItem = true;
        } else {
          renderItem = item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        }
        if (renderItem) {
          renderedItems += 1;
          return renderItem;
        }
      }
      return false;
    });
    this.setState({ professionalValues: unselectedPersonalInterests });
  };

  coachReviewsRef = React.createRef();

  isProfessionalInterestsChanged = () => {
    const professionalInterests = this.props.professionalInterests;
    const newProfessionalInterests = this.state.professionalInterests;

    if (professionalInterests && newProfessionalInterests && professionalInterests.length !== newProfessionalInterests.length) {
      return true;
    }

    let matchedInterestsQty = 0;
    if (professionalInterests) {
      professionalInterests.forEach((pi) => {
        if (newProfessionalInterests) {
          newProfessionalInterests.forEach((newPi) => {
            if (pi.name === newPi.name) {
              matchedInterestsQty += 1;
            }
          });
        }
      });
    }

    if (professionalInterests && matchedInterestsQty === professionalInterests.length) {
      return false;
    }
    return true;
  };

  isPersonalInterestsChanged = () => {
    const personalInterests = this.props.personalInterests;
    const newPersonalInterests = this.state.personalInterests;

    if (personalInterests && newPersonalInterests && personalInterests.length !== newPersonalInterests.length) {
      return true;
    }

    let matchedInterestsQty = 0;
    personalInterests.forEach((pi) => {
      newPersonalInterests.forEach((newPi) => {
        if (pi.name === newPi.name) {
          matchedInterestsQty += 1;
        }
      });
    });

    if (personalInterests && matchedInterestsQty === personalInterests.length) {
      return false;
    }
    return true;
  };

  scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  addPersonalInterest = (interest) => {
    const interests = this.state.personalInterests ? this.state.personalInterests : [];
    interests.push(interest);

    const unselectedPersonalInterests = this.state.unselectedPersonalInterests;
    const index = unselectedPersonalInterests.indexOf(interest);
    if (index !== -1) {
      unselectedPersonalInterests.splice(index, 1);
    }

    this.setState({
      personalInterests: interests,
      unselectedPersonalInterests
    });
  };

  addProfessionalInterest = (interest) => {
    const interests = this.state.professionalInterests ? this.state.professionalInterests : [];
    interests.push(interest);

    const unselectedProfessionalInterests = this.state.unselectedProfessionalInterests;
    const index = unselectedProfessionalInterests.indexOf(interest);
    if (index !== -1) {
      unselectedProfessionalInterests.splice(index, 1);
    }

    this.setState({
      professionalInterests: interests,
      unselectedProfessionalInterests
    });
  };

  editingBio = (event) => {
    if (event) {
      this.setState({ bio: event.target.value });
    }
  };

  editingOtherPro = (event) => {
    if (event) {
      this.setState({ textOtherProfessional: event.target.value });
    }
  };

  editingOtherPersonal = (event) => {
    if (event) {
      this.setState({ textOtherPersonal: event.target.value });
    }
  };

  didTutorEdited = (event) => {
    const { isEditing, bio, textOtherPersonal, textOtherProfessional, updatedPhoto } = this.state;
    const { photoURL } = this.props;
    const image = updatedPhoto ? updatedPhoto.preview : photoURL;

    if (
      isEditing &&
      (bio !== this.props.bio ||
        this.isProfessionalInterestsChanged() ||
        this.isPersonalInterestsChanged() ||
        textOtherProfessional !== this.props.textOtherProfessional ||
        textOtherPersonal !== this.props.textOtherPersonal ||
        image !== photoURL)
    ) {
      // eslint-disable-next-line
      event.returnValue = '';
    }
  };

  clearChanges = () => {
    window.removeEventListener('beforeunload', this.didTutorEdited);
    this.setState({
      bio: this.props.bio,
      personalInterests: this.props.personalInterests,
      professionalInterests: this.props.professionalInterests,
      textOtherProfessional: this.props.textOtherProfessional,
      textOtherPersonal: this.props.textOtherPersonal,
      updatedPhoto: null
    });
  };

  removeProfessionalInterest = (interest) => {
    if (interest === 'other') {
      this.setState({ textOtherProfessional: '' });
    } else {
      const interests = this.state.professionalInterests;
      const index = interests.indexOf(interest);
      if (index !== -1 && interests && interests.length > 1) {
        interests.splice(index, 1);
        const unselectedProfessionalInterests = this.state.unselectedProfessionalInterests ? this.state.unselectedProfessionalInterests : [];
        unselectedProfessionalInterests.push(interest);
        this.setState({
          professionalInterests: interests,
          unselectedProfessionalInterests
        });
      } else {
        this.showMinTagError();
      }
    }
  };
  removePersonalInterest = (interest) => {
    if (interest === 'other') {
      this.setState({ textOtherPersonal: '' });
    } else {
      const interests = this.state.personalInterests;
      const index = interests.indexOf(interest);
      if (index !== -1 && interests && interests.length > 1) {
        interests.splice(index, 1);
        const unselectedPersonalInterests = this.state.unselectedPersonalInterests ? this.state.unselectedPersonalInterests : [];
        unselectedPersonalInterests.push(interest);
        this.setState({
          personalInterests: interests,
          unselectedPersonalInterests
        });
      } else {
        this.showMinTagError();
      }
    }
  };

  fetchUnselectedTags = () => {
    const { personalInterests, professionalInterests } = this.state;
    const selectedPersonalInterestsId = personalInterests ? personalInterests.map((interest) => interest.id) : [];
    const selectedProfessionalInterestsId = professionalInterests ? professionalInterests.map((interest) => interest.id) : [];
    const headers = { Authorization: `Token ${this.props.token}` };
    axios
      .get('/api/onboarding/', { headers })
      .then((response) => {
        const unselectedPersonalInterests = response.data.personal_interests.filter((pi) => {
          if (!selectedPersonalInterestsId.includes(pi.id)) {
            return pi;
          }
          return false;
        });
        const unselectedProfessionalInterests = response.data.professional_interests.filter((pi) => {
          if (!selectedProfessionalInterestsId.includes(pi.id)) {
            return pi;
          }
          return false;
        });
        this.setState({
          unselectedProfessionalInterests,
          unselectedPersonalInterests
        });
      })
      .catch((err) => {
        this.props.handleExpiredToken(err);
      });
  };

  saveChanges = () => {
    if (!this.state.consent) {
      return;
    }
    const formData = new FormData();
    const { token, userId } = this.props;
    const { bio, professionalInterests, personalInterests, textOtherProfessional, textOtherPersonal, updatedPhoto } = this.state;
    if (updatedPhoto) {
      window.URL.revokeObjectURL(updatedPhoto.preview);
      formData.append('photo', updatedPhoto);
    }

    this.props.updateUserProfile(
      token,
      userId,
      formData,
      () => this.showOnUpdateSuccess(),
      () => this.showOnUpdateError()
    );
    const selectedProfessionalInterestIds = professionalInterests ? professionalInterests.map((pi) => pi.id) : [];
    const selectedPersonalInterestIds = personalInterests ? personalInterests.map((pi) => pi.id) : [];

    const headers = { Authorization: `Token ${token}` };
    const onboardingData = {
      bio,
      selectedProfessionalInterestIds,
      selectedPersonalInterestIds,
      textOtherProfessional,
      textOtherPersonal
    };
    axios
      .patch('/api/store_tutor_onboarding/', onboardingData, { headers })
      .then(() => {
        this.props.getTutorInfo(token);
      })
      .catch((err) => {
        this.props.handleExpiredToken(err);
      })
      .then(() => {
        this.setState({ isEditing: false });
      });
  };

  clearProfessionalSuggestions = () => {
    this.setState({ professionalTag: '' });
  };

  clearPersonalSuggestions = () => {
    this.setState({ personalTag: '' });
  };

  renderPhoto = (image) =>
    this.state.isEditing ? (
      <>
        <ProfilePhoto photoURL={image} />
        <Dropzone
          className={styles.dropZone}
          multiple={false}
          accept="image/*"
          onDrop={this.onDrop}
          onDropRejected={this.showOnDropRejected}
          maxSize={2097152}>
          Update photo
        </Dropzone>
      </>
    ) : (
      <ProfilePhoto photoURL={image} />
    );

  showOnUpdateError = () => {
    this.setState({ shouldShowOnUpdateError: true });
  };

  closeShowOnUpdateError = () => {
    this.setState({ shouldShowOnUpdateError: false });
  };

  showOnUpdateSuccess = () => {
    this.setState({ shouldShowOnUpdateSuccess: true });
  };

  closeshowOnUpdateSuccess = () => {
    this.setState({ shouldShowOnUpdateSuccess: false });
  };

  showMinTagError = () => {
    this.setState({ shouldShowMinTagError: true });
  };

  closeShowMinTagError = () => {
    this.setState({ shouldShowMinTagError: false });
  };

  render() {
    const {
      name,
      location,
      languages,
      rating,
      reviews,
      userType,
      calendarMatrix,
      topReviews,
      inReview,
      photoURL,
      checkProfile,
      surName,
      confetti,
      user,
      openBookingModal,
      openBookingModalAction,
      showBooking,
      openChangeCoachModal,
      setOpenChangeCoachModalAction
    } = this.props;

    const {
      professionalInterests,
      personalInterests,
      textOtherProfessional,
      textOtherPersonal,
      isEditing,
      consent,
      professionalTag,
      personalTag,
      personalValues,
      professionalValues,
      updatedPhoto
    } = this.state;

    let { bio } = this.state;
    bio = bio.split('\n').map((text, index) => <p key={index}>{text}</p>);

    const image = updatedPhoto ? updatedPhoto.preview : photoURL;

    return (
      <div className={styles.container}>
        {userType === 'student' ? (
          confetti && !checkProfile ? (
            <div className={styles.tutorPreviewHeader}>
              <h2 className={[styles.tutorPreviewTitle, styles.centerText].join(' ')}>We found the best match for you!</h2>
            </div>
          ) : null
        ) : !checkProfile ? (
          <div className={styles.tutorPreviewHeader}>
            {!isEditing ? (
              <>
                <h1 className={styles.tutorPreviewTitle}>This is how your future students see you!</h1>
                <Button
                  onClick={() => {
                    this.setState({ isEditing: true });
                    window.addEventListener('beforeunload', this.didTutorEdited);
                  }}>
                  Edit my profile
                </Button>
              </>
            ) : (
              <div className={styles['editing-wrapper']}>
                <CheckboxField
                  checked={consent}
                  onChange={() => this.setState({ consent: !consent })}
                  label={
                    <p>
                      Media (photos, text, videos) uploaded here may be visible on your profile by other users of our service. By uploading this media
                      you agree with our&nbsp;
                      <a href="https://chatterbox.io/privacy-policy/" rel="noopener noreferrer" target="_blank">
                        privacy policy
                      </a>
                      .
                    </p>
                  }
                />
                <div className={styles.editingHeaderButtonsContainer}>
                  <Button
                    onClick={() => {
                      this.setState({
                        isEditing: !isEditing
                      });
                      this.clearChanges();
                    }}
                    outfit="secondaryButton">
                    Cancel editing
                  </Button>
                  <Button
                    disabled={!consent}
                    outfit="greenButton"
                    onClick={() => {
                      this.saveChanges();
                      window.removeEventListener('beforeunload', this.didTutorEdited);
                    }}
                    className={styles.saveChangesButton}>
                    Save changes
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : null}

        <div className={styles.tutorNameHeader} />

        <div className={styles.tutorInfoContainer}>
          <div className={styles.columnLeft}>
            <div className={styles.desktopPhoto}>{this.renderPhoto(image)}</div>
            <div className={styles.mobilePhoto}>{this.renderPhoto(image)}</div>
            <div className={styles.tutorInfo}>
              {showBooking && userType === 'student' && !user.is_banco_unico_special_rules && (
                <div className="tutorInfo__buttonContainer">
                  <Button
                    onClick={() => {
                      if (userType === 'student') {
                        if (this.props.isChangeCoachProcessActive) {
                          setOpenChangeCoachModalAction(true);
                        } else {
                          openBookingModalAction(true);
                        }
                      }
                    }}
                    outfit="regularButton">
                    Book your {capitalize('session')}
                  </Button>
                </div>
              )}
              {rating && (
                <>
                  <ReactStars className="stars" count={5} size={30} edit={false} value={rating} color1="#e7ce9b" color2="#ef9349" />
                  <div
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (topReviews && topReviews.length > 0) {
                        this.scrollToRef(this.coachReviewsRef);
                      }
                    }}>
                    <h2 style={topReviews && topReviews.length > 0 ? { marginTop: 'unset', cursor: 'pointer' } : { marginTop: 'unset' }}>
                      {rating} ({reviews})
                    </h2>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={styles.columnRight}>
            <h1 className={styles.meet}>
              Meet {name} {surName}
            </h1>
            <h2 className={styles.categoryTitle}>Biography</h2>
            <div className={styles.textContainer}>
              {isEditing ? (
                <div className={styles['bio-textarea']}>
                  <label htmlFor="bio-textarea">Tell the students a bit about yourself.</label>
                  <textarea id="bio-textarea" onChange={this.editingBio} value={this.state.bio} />
                </div>
              ) : (
                <>{bio && bio.length > 0 ? <p className={styles.bio}>{bio}</p> : null}</>
              )}
              {((professionalInterests && professionalInterests.length > 0) ||
                (textOtherProfessional && textOtherProfessional.length !== 0) ||
                isEditing) && (
                <h2 className={[styles.category, styles.categoryTitle].join(' ')}>
                  <BriefcaseIcon />
                  <b>Professional Background:</b>
                </h2>
              )}
              {professionalInterests && professionalInterests.length > 0 && !isEditing && (
                <>
                  <p className={styles.languagesText}>
                    {professionalInterests
                      ? professionalInterests.map((interest, i) => {
                          if (i === 0) {
                            return interest.name;
                          }
                          return `, ${interest.name}`;
                        })
                      : []}
                  </p>
                </>
              )}
              {isEditing && (
                <>
                  <AutoSuggestTags
                    suggestions={professionalValues !== undefined ? professionalValues : []}
                    valueInput={professionalTag}
                    values={professionalValues}
                    onValueChange={this.onProfessionalValueChange}
                    clearSuggestions={this.clearProfessionalSuggestions}
                    getSuggestions={this.getProfessionalSuggestions}
                    addTag={this.addProfessionalInterest}
                    limitReached={professionalInterests && professionalInterests.length >= 5}
                  />
                  {professionalInterests
                    ? professionalInterests.map((interest, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <p style={{ marginBottom: 0, marginRight: 5 }} className={styles.languagesText}>
                            {interest.name}
                          </p>
                          <button onClick={() => this.removeProfessionalInterest(interest)} className={styles.autocompleteTagCross}>
                            X
                          </button>
                        </div>
                      ))
                    : []}
                  <div className={styles.editingOtherPro}>
                    <label htmlFor="professional-interests" className={styles.mainLabel}>
                      Is your profession not listed? Please add it here:
                    </label>
                    <RegularFormField
                      id="professional-interests"
                      label="Fill with other professional interests."
                      onChange={this.editingOtherPro}
                      value={textOtherProfessional}
                    />
                  </div>
                  {textOtherProfessional && textOtherProfessional.length === 200 ? (
                    <span
                      style={{
                        color: 'red',
                        display: 'block'
                      }}>
                      {0} characters remaining.
                    </span>
                  ) : (
                    <span style={{ display: 'block' }}>{200 - (textOtherProfessional ? textOtherProfessional.length : 0)} characters remaining.</span>
                  )}
                </>
              )}

              {userType === 'student'
                ? textOtherProfessional &&
                  textOtherProfessional.length !== 0 &&
                  !inReview && <p className={styles.languagesText}>{textOtherProfessional}</p>
                : textOtherProfessional &&
                  textOtherProfessional.length !== 0 &&
                  !isEditing && (
                    <>
                      <p className={[styles.otherText, styles.languagesText].join(' ')}>{textOtherProfessional}</p>
                      <label className={[styles.secondLabel, styles.inReview].join(' ')}>in review</label>
                    </>
                  )}

              {((personalInterests && personalInterests.length > 0) || (textOtherPersonal && textOtherPersonal.length !== 0) || isEditing) && (
                <h2 className={[styles.category, styles.categoryTitle].join(' ')}>
                  <PlayIcon />
                  <b>Personal Interests:</b>
                </h2>
              )}

              {personalInterests && !isEditing && (
                <p className={styles.languagesText}>
                  {personalInterests.map((interest, i) => {
                    if (i === 0) {
                      return interest.name;
                    }
                    return `, ${interest.name}`;
                  })}
                </p>
              )}

              {isEditing && (
                <>
                  <AutoSuggestTags
                    suggestions={personalValues !== undefined ? personalValues : []}
                    valueInput={personalTag}
                    values={personalValues}
                    onValueChange={this.onPersonalValueChange}
                    clearSuggestions={this.clearPersonalSuggestions}
                    getSuggestions={this.getPersonalSuggestions}
                    addTag={this.addPersonalInterest}
                    limitReached={personalInterests && personalInterests.length >= 5}
                  />
                  {personalInterests
                    ? personalInterests.map((interest) => (
                        <div
                          key={interest.name}
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <p className={styles.languagesText}>{interest.name}</p>
                          <button onClick={() => this.removePersonalInterest(interest)} className={styles.autocompleteTagCross}>
                            X
                          </button>
                        </div>
                      ))
                    : []}
                  <div className={styles.editingOtherPro}>
                    <label className={styles.mainLabel}>Is your personal interest not listed? Please add it here:</label>
                    <RegularFormField label="Fill with other personal interests." onChange={this.editingOtherPersonal} value={textOtherPersonal} />
                  </div>
                  {textOtherPersonal && textOtherPersonal.length === 200 ? (
                    <span
                      style={{
                        color: 'red',
                        display: 'block'
                      }}>
                      {0} characters remaining.
                    </span>
                  ) : (
                    <span style={{ display: 'block' }}>{200 - (textOtherPersonal ? textOtherPersonal.length : 0)} characters remaining.</span>
                  )}
                </>
              )}
              {userType === 'student' ? (
                textOtherPersonal && textOtherPersonal.length !== 0 && !inReview && <p className={styles.languagesText}>{textOtherPersonal}</p>
              ) : textOtherPersonal && textOtherPersonal.length !== 0 && !isEditing && inReview ? (
                <>
                  <p className={[styles.otherText, styles.languagesText].join(' ')}>{textOtherPersonal}</p>
                  <label className={[styles.secondLabel, styles.inReview].join(' ')}>in review</label>
                </>
              ) : (
                <p className={styles.languagesText}>{textOtherPersonal}</p>
              )}
              {languages && languages.length > 0 && (
                <h2 className={[styles.category, styles.categoryTitle].join(' ')}>
                  <MicrophoneIcon />
                  <b>Languages:</b>
                </h2>
              )}
              {languages &&
                languages.map((language, index) => (
                  <p key={index} className={styles.languagesText}>
                    {this.getLanguageDisplay(language, index)}
                  </p>
                ))}
              {languages && !languages.some((l) => l.language.toLowerCase() === 'english') && <p className={styles.languagesText}>English</p>}
              {location && location.country && (
                <>
                  <h2 className={[styles.category, styles.categoryTitle].join(' ')}>
                    <PersonIcon />
                    <b>Country of Origin:</b>
                  </h2>
                  <p className={styles.languagesText}>{location.country}</p>
                </>
              )}
              {isEditing && (
                <label style={{ marginTop: 8 }} className={styles.secondLabel}>
                  Languages, session types and country of origin cannot be edited directly by you. If these look wrong or you want to make changes to
                  them, please contact us at <a href="mailto:community@chatterbox.io">community@chatterbox.io</a>.
                </label>
              )}
              {!user.is_banco_unico_special_rules && (
                <>
                  <h2 className={[styles.sectionHeader, styles.categoryTitle].join(' ')}>
                    <b>{name}&apos;s Availability</b>
                    {isEditing && (
                      <Button
                        outfit="greenButton"
                        onClick={() => {
                          this.props.history.push('/calendar');
                        }}>
                        Edit my availabilities
                      </Button>
                    )}
                  </h2>

                  <Timetable disabled calendarMatrix={calendarMatrix} onSelectTimeSlot={() => {}} />
                </>
              )}

              {topReviews.length > 0 && (
                <h2 ref={this.coachReviewsRef} className={[styles.sectionHeader, styles.categoryTitle].join(' ')}>
                  <b>What students say about {name}</b>
                </h2>
              )}
              {topReviews.map((rev) =>
                rev.show_on_profile ? (
                  <>
                    <div className={styles.quoteContainer}>
                      <img className={styles.quoteIcon} src={IconQuote} alt="quote" />
                      <p className={styles.languagesText}>
                        <i>{rev.text}</i>
                      </p>
                    </div>
                    <p className={styles.quoteName}>{rev.student}</p>
                  </>
                ) : undefined
              )}
            </div>
          </div>
        </div>
        {isEditing && (
          <Prompt
            message={() => 'You have unsaved changes. Exit the page?'}
            when={
              isEditing &&
              (this.state.bio !== this.props.bio ||
                this.isProfessionalInterestsChanged() ||
                this.isPersonalInterestsChanged() ||
                textOtherProfessional !== this.props.textOtherProfessional ||
                textOtherPersonal !== this.props.textOtherPersonal ||
                image !== photoURL)
            }
          />
        )}
        <ErrorToast
          errorMsg="Maximum image upload size is 2MB."
          onClose={this.closeOnDropRejected.bind(this)}
          show={this.state.shouldShowOnDropRejected}
        />
        <ErrorToast errorMsg="Update failed." onClose={this.closeShowOnUpdateError.bind(this)} show={this.state.shouldShowOnUpdateError} />
        <ErrorToast errorMsg="Minimum of 1 Tag." onClose={this.closeShowMinTagError.bind(this)} show={this.state.shouldShowMinTagError} />
        <SuccessToast
          msg="Your profile was successfully updated!"
          onClose={this.closeshowOnUpdateSuccess.bind(this)}
          show={this.state.shouldShowOnUpdateSuccess}
        />

        {openBookingModal && <BookingTuitionSessionModal redirectTo="/study-area" />}
        {openChangeCoachModal && <ChangeCoachModal redirectTo="/bookings" />}
      </div>
    );
  }
}

TutorMatchProfile.propTypes = {
  assignedCourse: PropTypes.object.isRequired,
  bio: PropTypes.string,
  languages: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  surName: PropTypes.string.isRequired,
  photoURL: PropTypes.string,
  calendarMatrix: PropTypes.array.isRequired,
  topReviews: PropTypes.array,
  rating: PropTypes.number.isRequired,
  reviews: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  professionalInterests: PropTypes.array.isRequired,
  textOtherProfessional: PropTypes.string.isRequired,
  personalInterests: PropTypes.array.isRequired,
  textOtherPersonal: PropTypes.string.isRequired,
  inReview: PropTypes.bool,
  token: PropTypes.string,
  userId: PropTypes.number,
  updateUserProfile: PropTypes.func.isRequired,
  getTutorInfo: PropTypes.func.isRequired,
  checkProfile: PropTypes.bool,
  handleExpiredToken: PropTypes.func.isRequired,
  confetti: PropTypes.bool,
  isChangeCoachProcessActive: PropTypes.bool.isRequired,
  openBookingModal: PropTypes.bool.isRequired,
  openBookingModalAction: PropTypes.func.isRequired,
  showBooking: PropTypes.bool,
  openChangeCoachModal: PropTypes.bool.isRequired,
  setOpenChangeCoachModalAction: PropTypes.func.isRequired,
  cleanBookingsPreviousStateAction: PropTypes.func.isRequired,
  getSessionsStatusAction: PropTypes.func.isRequired
};

TutorMatchProfile.defaultProps = {
  bio: '',
  photoURL: { ProfilePlaceholder },
  topReviews: [],
  inReview: false,
  token: '',
  userId: 0,
  checkProfile: false,
  confetti: false
};

const mapStateToProps = (state) => ({
  assignedCourse: state.onboardingMatch.assignedCourse,
  openBookingModal: state.newBooking.bookingLessons.openBookingModal,
  openChangeCoachModal: state.changeCoach.openChangeCoachModal,
  isChangeCoachProcessActive: state.changeCoach.isChangeCoachProcessActive,
  token: state.user.token
});

export default connect(mapStateToProps, {
  getSessionsStatusAction: getSessionsStatus,
  openBookingModalAction: openBookingModal,
  setOpenChangeCoachModalAction: setOpenChangeCoachModal,
  cleanBookingsPreviousStateAction: cleanPreviousState
})(TutorMatchProfile);
