import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import { Alert, Button } from 'react-bootstrap';
import Modal from '../components/uikit/Modal';
import Header from '../components/uikit/Header';
import ErrorAlert from '../components/uikit/ErrorAlert';
import AvailabilityWeek from '../components/availability/AvailabilityWeek';
import Calendar, { ABSENCE_PERIOD_EVENT, APPOINTMENT_EVENT, AVAILABILITY_EVENT, BREAK_EVENT } from '../components/calendar/Calendar';
import TimeOff from '../components/calendar/TimeOff';
import PontualAvail from '../components/calendar/PontualAvail';
import Mixpanel from '../utils/mixpanel-helper.js';

import { createAbsencePeriod, deleteAbsencePeriod, getAbsencePeriods, updateAbsencePeriod } from '../actions/absence-periods';
import { getAppointmentsForTutor } from '../actions/appointments';
import { getUserAvailabilities, getAllUserAvailabilities, updateAvailability } from '../actions/availabilities';
import { setTimezone, getAvailableTimezones } from '../actions/time';

import { updateAccount } from '../actions/user';
import { getTimeRangeDisplay, getMonthDisplay, getDayDisplay, getDateDisplay, ENDPOINT_DATE_FORMAT } from '../utils/time-helpers';

import DropdownAsync from '../components/design-system/dropdown-async/DropdownAsync';

import appStyles from '../style/containers/App.module.scss';
import formStyles from '../style/utils/_form.module.scss';
import styles from '../style/containers/TutorCalendar.module.scss';

const WEEK_LENGTH = 7;
const THREE_MONTHS_LENGTH = 84;

const STATUS_DISPLAYS = {
  booked: 'Booked',
  canceled: 'Cancelled',
  canceled_late: 'Cancelled late',
  happened: 'Happened',
  student_noshow: 'Student no show',
  tutor_noshow: 'Tutor no show',
  technical_error: 'Technical error'
};

const STATUS_EXPLANATIONS = {
  booked: 'Confirmed appointment, has not happened yet.',
  canceled: 'Cancelled with sufficient notice - you will not be paid for these.',
  canceled_late: 'Cancelled late by the learner - you will be paid half the usual fee.',
  happened: 'Appointment has happened but not been paid yet.',
  student_noshow: 'The learner did not show for the appointment - you will be paid your full regular fee for this booking.',
  tutor_noshow: 'The tutor did not show for the appointment - you will not be paid for these.',
  technical_error: 'The appointment was affected by technical issues - you will be paid your full regular fee for this booking.'
};

function getSunday(d) {
  const day = d.getDay();
  const diff = d.getDate() - day; // adjust when day is sunday
  return new Date(d.setDate(diff));
}

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

class TutorCalendar extends Component {
  constructor(props) {
    super(props);

    const dates = {};
    const allDates = {};
    const weekStart = moment(this.getWeekStart());

    for (let i = 0; i < WEEK_LENGTH; i += 1) {
      dates[moment(weekStart).add(i, 'days').format(ENDPOINT_DATE_FORMAT)] = [];
    }

    for (let i = 0; i < THREE_MONTHS_LENGTH; i += 1) {
      allDates[moment(weekStart).add(i, 'days').format(ENDPOINT_DATE_FORMAT)] = [];
    }

    this.state = {
      currentWeek: dates,
      currentThreeMonths: allDates,
      errMsg: null,
      error: false,
      events: [],
      isLoading: false,
      loadingAppointments: true,
      otherDates: [],
      success: false,
      apptsToHonor: [],
      showApptsToHonor: false,
      studentsApptsData: null
    };
  }

  componentDidMount() {
    const { userId, token } = this.props;
    const dates = Object.keys(this.state.currentWeek);
    const start = dates[0];
    const end = dates[WEEK_LENGTH - 1];
    this.props.getUserAvailabilities(token, userId, start, end, this.showError);

    this.getAllUserAvailabilities();
    this.props.getAbsencePeriods(token, userId, this.showError);
    const today = new Date();
    const startDate = getSunday(today);
    const endDate = addDays(startDate, 6);
    this.props.getAppointmentsForTutor(token, userId, this.showError, startDate, endDate, this.finishLoading);
    // Show a success toast if the tutor confirmed from the email that the calendar is up to date
    if (this.props.match.params && this.props.match.params.calendarIsUpdated === 'true') {
      toast.success('Thank you for confirming your calendar availabilities.');
    }
    Mixpanel.track('Visited calendar');
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { availabilities } = this.props;
      const currentWeek = {};

      // eslint-disable-next-line no-unused-vars
      for (const date in this.state.currentWeek) {
        if (date) {
          currentWeek[date] = [];
        }
      }

      const otherDates = {};
      const updatedDates = new Set(availabilities.map((av) => av.date));

      // eslint-disable-next-line no-unused-vars
      for (const date of updatedDates) {
        if (date) {
          const dateAvailabilities = availabilities.filter((av) => av.date === date);

          if (this.state.currentWeek[date]) {
            currentWeek[date] = dateAvailabilities;
          } else {
            otherDates[date] = dateAvailabilities;
          }
        }
      }

      this.onUpdate(currentWeek, otherDates);
    }
  }

  onUpdate = (currentWeek, otherDates) => {
    this.setState({ currentWeek, events: this.getEvents(), otherDates });
  };

  onNavigate = (date) => {
    this.setState({
      loadingAppointments: true
    });
    const { userId, token } = this.props;
    const startDate = getSunday(date);
    const endDate = addDays(startDate, 6);
    this.props.getAppointmentsForTutor(token, userId, this.showError, startDate, endDate, this.finishLoading);
  };

  getAllUserAvailabilities = () => {
    const { token, userId } = this.props;
    const allDates = Object.keys(this.state.currentThreeMonths);
    const fullStart = allDates[0];
    const fullEnd = allDates[THREE_MONTHS_LENGTH - 1];
    this.props.getAllUserAvailabilities(token, userId, fullStart, fullEnd, this.showError);
  };

  getMoreAvailabilities = (date) => {
    const { userId, token } = this.props;

    const start = moment(date).subtract(WEEK_LENGTH, 'days').toDate();

    const end = moment(date).add(WEEK_LENGTH, 'days').toDate();

    this.props.getUserAvailabilities(token, userId, start, end, this.showError);
  };

  getWeekStart = () => {
    const today = new Date();
    const adjustedOffset = today.getDay();
    return moment(today).subtract(adjustedOffset, 'days').toDate();
  };

  getEvents() {
    let events = [];
    const { currentWeek, otherDates } = this.state;
    const { absencePeriods, appointments, timezone } = this.props;
    const allDates = { ...currentWeek, ...otherDates };
    // eslint-disable-next-line no-unused-vars
    for (const date in allDates) {
      if (date) {
        const availabilities = allDates[date];
        events = [
          ...events,
          ...(availabilities?.length ? availabilities : []).map((av) => ({
            end: moment(`${av.date} ${av.to}`).toDate(),
            start: moment(`${av.date} ${av.from}`).toDate(),
            title: 'Available',
            type: AVAILABILITY_EVENT
          }))
        ];
      }
    }

    events = [
      ...events,
      ...(absencePeriods?.length ? absencePeriods : []).map((period) => {
        const endUTC = moment.tz(`${period.end}`, 'UTC');
        const startUTC = moment.tz(`${period.start}`, 'UTC');

        const endLocal = endUTC.clone().tz(timezone);
        const startLocal = startUTC.clone().tz(timezone);

        const startDate = new Date(startLocal.year(), startLocal.month(), startLocal.date(), startLocal.hour(), startLocal.minute());

        const endDate = new Date(endLocal.year(), endLocal.month(), endLocal.date(), endLocal.hour(), endLocal.minute());

        return {
          end: endDate,
          id: period.id,
          start: startDate,
          title: 'Time off',
          type: ABSENCE_PERIOD_EVENT
        };
      })
    ];

    events = [
      ...events,
      ...(appointments?.length ? appointments : []).map((ap) => {
        const endUTC = moment.tz(`${ap.date} ${ap.end_time}`, 'UTC');
        const startUTC = moment.tz(`${ap.date} ${ap.start_time}`, 'UTC');

        const endLocal = endUTC.clone().tz(timezone);
        const startLocal = startUTC.clone().tz(timezone);

        const endDateObj = new Date(endLocal.year(), endLocal.month(), endLocal.date(), endLocal.hour(), endLocal.minute());

        const startDateObj = new Date(startLocal.year(), startLocal.month(), startLocal.date(), startLocal.hour(), startLocal.minute());

        return {
          end: endDateObj,
          id: ap.id,
          start: startDateObj,
          status: ap.status,
          title: '',
          type: APPOINTMENT_EVENT
        };
      })
    ];

    // add visually 15 min breaks
    events = [
      ...events,
      ...(appointments?.length ? appointments : [])
        .filter((ap) => ap.status !== 'canceled')
        .map((ap) => {
          const endUTC = moment.tz(`${ap.date} ${ap.end_time}`, 'UTC').add(15, 'minutes');
          const startUTC = moment.tz(`${ap.date} ${ap.end_time}`, 'UTC');

          const endLocal = endUTC.clone().tz(timezone);
          const startLocal = startUTC.clone().tz(timezone);

          const endDateObj = new Date(endLocal.year(), endLocal.month(), endLocal.date(), endLocal.hour(), endLocal.minute());

          const startDateObj = new Date(startLocal.year(), startLocal.month(), startLocal.date(), startLocal.hour(), startLocal.minute());

          return {
            end: endDateObj,
            id: ap.id,
            start: startDateObj,
            status: ap.status,
            title: 'Break',
            type: BREAK_EVENT
          };
        })
    ];

    return events;
  }

  closeAlert = () => {
    this.setState({ success: false, error: false });
  };

  handleTimeZoneChange = (value) => {
    this.setState({ isLoading: true });

    this.props.updateAccount({ ...this.props.tutor, timezone: value, token: this.props.token }, this.handleTimeZoneChangeSuccess, () => {
      Mixpanel.track('Error', { error: 'update_timezone_error' });
      this.setState({ error: true, isLoading: false });
    });

    this.props.setTimezone(value);

    this.setState({ events: this.getEvents() });
  };

  handleTimeZoneChangeSuccess = () => {
    toast.success('Time zone saved');
    Mixpanel.track('Timezone successfully updated');
    this.setState({ isLoading: false });
  };

  hideApptsHonorModal = () => {
    this.setState({ apptsToHonor: [], showApptsToHonor: false });
  };

  apptsToHonor = (updatedWeekday) => {
    const { appointments } = this.props;
    const today = moment.utc();
    const studentsApptsData = {};
    const apptsToHonor = Array.isArray(appointments)
      ? appointments?.filter((ap) => moment(ap.date).weekday() === updatedWeekday && ap.status === 'booked' && moment(ap.date) >= today)
      : [];
    apptsToHonor.forEach((appt) => {
      appt.students.forEach((stu) => {
        if (!studentsApptsData[`${stu.id}`]) {
          studentsApptsData[`${stu.id}`] = {
            student: stu,
            appts: []
          };
        }
        studentsApptsData[`${stu.id}`].appts.push(appt);
      });
    });
    this.setState({ studentsApptsData });
    return apptsToHonor;
  };

  /* Update the rest of the availabilities in a day when one of its availabilties is edited. */
  renewDay = (date, updatedId, type, errorCallback) => {
    const { token } = this.props;
    const updatedAvailabilityMoment = moment(date);
    const updatedWeekday = updatedAvailabilityMoment.weekday();
    const weekStart = updatedAvailabilityMoment.startOf('week');
    if (type !== 'create') {
      const apptsToHonor = this.apptsToHonor(updatedWeekday);
      if (apptsToHonor.length > 0) {
        this.setState({ apptsToHonor, showApptsToHonor: true });
      }
    }
    Object.values(this.state.currentWeek)
      .reduce((x, y) => x.concat(y))
      .filter((avail) => avail.date !== date && avail.id !== updatedId)
      .forEach((avail) => {
        const dateMoment = moment(avail.date);
        const weekday = dateMoment.weekday(); // e.g. 2 for Tuesday

        if (weekday === updatedWeekday) {
          const newDateMoment = weekStart.clone().add(weekday, 'days');
          const newDateString = newDateMoment.format('YYYY-MM-DD');

          this.props.updateAvailability(token, newDateString, avail.from, avail.to, avail.id, avail.recur_weekly, errorCallback);
        }
      });
  };

  showError = (msg) => {
    this.setState({
      error: true,
      success: false,
      errMsg: msg,
      loadingAppointments: false
    });
    window.scrollTo(0, 0);
  };

  finishLoading = () => {
    this.setState({
      loadingAppointments: false
    });
  };

  showSuccess = () => {
    this.setState({
      error: false,
      success: true
    });
    window.scrollTo(0, 0);
  };

  getTimezones = () => {
    const { token } = this.props;
    this.props.getAvailableTimezones(token);
  };

  render() {
    const { absencePeriods, appointments, name, token, allAvailabilities, timezone, availableTimezones, isLoadingAvailableTimezones } = this.props;

    const { currentWeek, error, events, success, apptsToHonor, showApptsToHonor, studentsApptsData, loadingAppointments } = this.state;

    let className = styles.container;

    if (this.state.isLoading) {
      className += ` ${appStyles.loading}`;
    }

    const headerText = `${name}'s Calendar`;

    // const oneTimeAvailabilities = currentWeek

    const nonRecurrAvailabilities = [];
    if (allAvailabilities) {
      allAvailabilities.forEach((availability) => {
        if (!availability.recur_weekly) {
          nonRecurrAvailabilities.push(availability);
        }
      });
    }

    return (
      <div className={className}>
        <Header text={headerText} />

        {error && <ErrorAlert errorMsg={this.state.errMsg} />}

        {success && (
          <Alert className={formStyles.alert} bsStyle="success">
            Successfully updated!
          </Alert>
        )}

        <DropdownAsync
          label="Your timezone"
          onChange={this.handleTimeZoneChange}
          defaultValue={timezone}
          options={availableTimezones}
          getOptions={this.getTimezones}
          isLoading={isLoadingAvailableTimezones}
        />

        <h3 className={styles.heading}>Regular availability</h3>

        <p style={{ textAlign: 'center' }}>
          This will stay the same every week.
          <br />
          Tell us which times you will be available. You can edit this at any time.
          <br />
          <br />A 15-minutes break is automatically added after each booking.
        </p>
        {showApptsToHonor && apptsToHonor.length > 0 && studentsApptsData && (
          <Modal className={styles.modal} withCloseButton={false} onModalClose={this.hideApptsHonorModal}>
            <h1 style={{ marginTop: 'unset' }}>Appointments already scheduled</h1>
            <p>
              Your availability will be updated and no learner will be able to book at that time. <br />
              However, please keep in mind that the following appointments which already booked. <br />
              These appointments are already in your Calendar, this is just a reminder.
            </p>
            <div className={styles.apptsToHonorContainer}>
              {Object.keys(studentsApptsData).map((key) => (
                <>
                  <h2>
                    {studentsApptsData[key].student.first_name} {studentsApptsData[key].student.last_name}{' '}
                    <p style={{ display: 'inline-block' }}>({studentsApptsData[key].student.email})</p>
                  </h2>
                  <div style={{ marginLeft: 20 }}>
                    {studentsApptsData[key].appts.map((appointment) => {
                      let timeDisplay;
                      let dateDisplay;
                      timeDisplay = getTimeRangeDisplay(appointment.date, appointment.start_time, appointment.end_time, timezone);

                      const monthDisplay = getMonthDisplay(appointment.date, appointment.start_time, timezone);

                      const dayDisplay = getDayDisplay(appointment.date, appointment.start_time, timezone);

                      dateDisplay = getDateDisplay(appointment.date, appointment.start_time, timezone);

                      dateDisplay = `${dateDisplay.substr(0, 3)} ${dayDisplay.replace(/[^0-9.]+/g, '')} ${monthDisplay}`;

                      if (timeDisplay.includes('+')) {
                        timeDisplay = timeDisplay.slice(0, timeDisplay.indexOf('+') - 1);
                      }

                      let timeZoneCity;
                      if (!timezone) {
                        timeZoneCity = '';
                      } else if (timezone.split('/').length > 1) {
                        timeZoneCity = timezone.split('/')[1];
                      } else {
                        timeZoneCity = timezone.split('/')[0];
                      }
                      timeDisplay = `${timeDisplay.slice(0, 13)} ${timeZoneCity} time`;
                      return (
                        <p key={timeDisplay}>
                          {dateDisplay.toUpperCase()}, {timeDisplay}
                        </p>
                      );
                    })}
                  </div>
                </>
              ))}
            </div>
            <Button bsStyle="secondary" onClick={this.hideApptsHonorModal}>
              I am aware of the above appointments.
            </Button>
          </Modal>
        )}
        <AvailabilityWeek
          availabilities={currentWeek}
          errorCallback={this.showError}
          closeAlert={this.closeAlert}
          renewDay={this.renewDay}
          token={token}
        />
        <div className={styles['occasional-and-time-off']}>
          <div>
            <h3 className={styles.heading}>Occasional availability</h3>

            <p className="text-center">
              This will only happen on one occasion.
              <br />
              Add here any special, one-off availability you have.
              <br />
              You can edit it at any time.
            </p>

            <PontualAvail nonRecurrAvailabilities={nonRecurrAvailabilities} token={this.props.token} tutor={this.props.tutor} />
          </div>
          <div>
            <h3 className={styles.heading}>Time Off</h3>

            <p className="text-center">Manage your holidays and other unavailable times.</p>

            <TimeOff
              absencePeriods={absencePeriods}
              createAbsencePeriod={this.props.createAbsencePeriod}
              deleteAbsencePeriod={this.props.deleteAbsencePeriod}
              token={this.props.token}
              tutor={this.props.tutor}
              updateAbsencePeriod={this.props.updateAbsencePeriod}
              timezone={this.props.timezone}
            />
          </div>
        </div>

        <div className={styles.calendarHeader}>
          <div className={appStyles.calendarPlaceholder} />
          <h3 className={styles.heading}>Calendar</h3>
          <div className={[loadingAppointments ? appStyles.loading : undefined, styles.calendarLoader].join(' ')} />
        </div>

        <div className={styles.calendarInstructions}>
          <p className="text-center">Click an appointment to view details.</p>
        </div>

        <div className={styles.keysContainer}>
          {Object.keys(STATUS_EXPLANATIONS).map((status) => (
            <div className={[styles.keyContainer, styles.tooltip].join(' ')} key={status}>
              <div className={[styles.keyColour, styles[`keyColour-${status}`]].join(' ')} />
              <div className={styles.keyText}>{STATUS_DISPLAYS[status]}</div>
              <span className={styles.tooltiptext}>{STATUS_EXPLANATIONS[status]}</span>
            </div>
          ))}
        </div>

        <div className={styles.calendarContainer}>
          <Calendar
            appointments={appointments}
            errorCallback={this.showError}
            events={events}
            successCallback={this.showSuccess}
            token={token}
            tutor={this.props.tutor}
            updateEvents={this.getMoreAvailabilities}
            history={this.props.history}
            onNavigate={this.onNavigate}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    absencePeriods: state.absencePeriods || [],
    appointments: state.appointments || [],
    availabilities: state.availabilities || [],
    allAvailabilities: state.allAvailabilities || [],
    name: state.user.first_name,
    token: state.user.token,
    tutor: state.user,
    userId: state.user.id,
    timezone: state.time.timezone,
    availableTimezones: state.time.availableTimezones,
    isLoadingAvailableTimezones: state.time.isLoadingAvailableTimezones
  };
}

TutorCalendar.propTypes = {
  absencePeriods: PropTypes.array.isRequired,
  appointments: PropTypes.array.isRequired,
  availabilities: PropTypes.array.isRequired,
  allAvailabilities: PropTypes.array.isRequired,
  createAbsencePeriod: PropTypes.func.isRequired,
  deleteAbsencePeriod: PropTypes.func.isRequired,
  getAbsencePeriods: PropTypes.func.isRequired,
  getAppointmentsForTutor: PropTypes.func.isRequired,
  getUserAvailabilities: PropTypes.func.isRequired,
  getAllUserAvailabilities: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  updateAbsencePeriod: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  updateAvailability: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  timezone: PropTypes.string.isRequired,
  setTimezone: PropTypes.func.isRequired,
  getAvailableTimezones: PropTypes.func.isRequired,
  availableTimezones: PropTypes.array,
  isLoadingAvailableTimezones: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, {
  getAvailableTimezones,
  createAbsencePeriod,
  deleteAbsencePeriod,
  getAppointmentsForTutor,
  getAbsencePeriods,
  getUserAvailabilities,
  getAllUserAvailabilities,
  updateAbsencePeriod,
  updateAccount,
  updateAvailability,
  setTimezone
})(TutorCalendar);
