import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import absencePeriodReducer from './absence-period';
import appointmentsReducer from './appointments';
import appointmentTypeReducer from './appointment-type';
import availabilitiesReducer from './availabilities';
import bookableReducer from './bookable';
import invoicesReducer from './invoices';
import lastAppointmentReducer from './last-appointment';
import lessonReducer from './lesson';
import lessonsReducer from './lessons';
import coursesReducer from './courses';
import locationsReducer from './locations';
import orderInfoReducer from './order-info';
import profileReducer from './profile';
import tutorProfileReducer from './tutor-profile';
import tutorsReducer from './tutors';
import userReducer from './user';
import languagesReducer from './languages';
import NewLoginReducer from './new-login';
import selectedLanguageReducer from './selected-language';
import groupSessionsReducer from './group-sessions';
import ProductTypesReducer from './product-types';
import ChatReducer from './chat';
import OnboardingReducer from './onboarding';
import NewOnboardingReducer from './new-onboarding';
import SelectedTutorReducer from './selected-tutor';
import selectedProductTypeReducer from './selected-product-type';
import TutorInfoReducer from './tutor-info';
import CurrentCeregoAssignmentReducer from './current-cerego-assignment';
import allAvailabilitesReducer from './allAvailabilites';
import classroomReducer from './classroom';
import bookingFrequencyReducer from './booking-frequency';
import courseDetailsReducer from './course-details';
import bookingsReducer from './new-booking';
import changeCoachReducer from './change-coach';
import testPageReducer from './test-page';
import timeReducer from './time';
import formsReducer from './forms';
import sessionsReducer from './sessions';
import studentReducer from './student';
import selectedUnitReducer from './selected-unit';
import appLanguageReducer from './app-language';
import { HelpdeskReducer } from './helpdesk';
import appointmentToRate from './appointment-to-rate';

const rootReducer = combineReducers({
  absencePeriods: absencePeriodReducer,
  allAvailabilities: allAvailabilitesReducer,
  appLanguage: appLanguageReducer,
  appointments: appointmentsReducer,
  appointmentType: appointmentTypeReducer,
  availabilities: availabilitiesReducer,
  bookable: bookableReducer,
  bookingFrequency: bookingFrequencyReducer,
  changeCoach: changeCoachReducer,
  chat: ChatReducer,
  classroom: classroomReducer,
  courseDetails: courseDetailsReducer,
  courses: coursesReducer,
  currentCeregoAssignment: CurrentCeregoAssignmentReducer,
  form: formReducer,
  forms: formsReducer,
  groupSessions: groupSessionsReducer,
  helpdesk: HelpdeskReducer,
  invoices: invoicesReducer,
  languages: languagesReducer,
  lastAppointment: lastAppointmentReducer,
  appointmentToRate: appointmentToRate,
  lesson: lessonReducer,
  lessons: lessonsReducer,
  locations: locationsReducer,
  onboardingMatch: OnboardingReducer,
  newBooking: bookingsReducer,
  newLogin: NewLoginReducer,
  newOnboarding: NewOnboardingReducer,
  orderInfo: orderInfoReducer,
  productTypes: ProductTypesReducer,
  profile: profileReducer,
  selectedLanguage: selectedLanguageReducer,
  selectedProductType: selectedProductTypeReducer,
  selectedTutor: SelectedTutorReducer,
  selectedUnit: selectedUnitReducer,
  sessions: sessionsReducer,
  student: studentReducer,
  tutorInfo: TutorInfoReducer,
  tutorProfile: tutorProfileReducer,
  tutors: tutorsReducer,
  user: userReducer,
  testPage: testPageReducer,
  time: timeReducer
});

export default rootReducer;
