import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../design-system/button/Button';
import Modal from '../../../design-system/modal/Modal';
import mixpanel from '../../../../utils/mixpanel-helper';

import { getFutureSchedule } from '../../../../actions/bookable';
import { initChangeCoachProcess, setChangeCoachMotivation, setChangeCoachSuccess, setOpenChangeCoachModal } from '../../../../actions/change-coach';

import styles from './ChangeCoachModal.module.scss';

import { cancelMultipleBookingsAndGetUpdatedStudentsBooking, createAppointmentAndRedirectToAnotherPage } from '../../../../actions/new-booking';
import BookingTuitionSessionModal from '../booking/BookingTuitionSessionModal';

const ChangeCoachModal = ({
  cancelMultipleBookingsAndGetUpdatedStudentsBookingAction,
  conversationClassesStatus,
  tuitionLessonsStatus,
  initChangeCoachProcessAction,
  isCancellingAppointment,
  isCreatingAppointment,
  isLoadingLessonsStatus,
  selectedCourseId,
  setChangeCoachMotivationAction,
  setChangeCoachSuccessAction,
  setOpenChangeCoachModalAction,
  student
}) => {
  const [changeCoachMotivation, setStateCoachMotivation] = useState('');
  const [completionProgress, setCompletionProgress] = useState(50);
  const [currentStep, setCurrentStep] = useState(0);
  const [lessonsToBeCanceled, setLessonsToBeCanceled] = useState([]);

  const splitLessonsDependingOnStatus = () => {
    if (!tuitionLessonsStatus?.booked || !conversationClassesStatus?.booked) return;

    setLessonsToBeCanceled([...tuitionLessonsStatus.booked, ...conversationClassesStatus.booked] || []);
  };

  useEffect(() => {
    splitLessonsDependingOnStatus();
  }, [tuitionLessonsStatus]);

  const getAppointmentsToBeCancelledIds = () => lessonsToBeCanceled.map(({ appointment_object: appointment }) => appointment.id);

  const executeAppointmentCancelation = (changeMotivation, callback) => {
    const appointmentsToBeCancelledIds = getAppointmentsToBeCancelledIds();

    if (!appointmentsToBeCancelledIds.length) {
      callback();
    } else {
      cancelMultipleBookingsAndGetUpdatedStudentsBookingAction({
        studentToken: student.token,
        courseId: selectedCourseId,
        appointmentsIds: appointmentsToBeCancelledIds,
        cancellationMotive: `CHANGE MOTIVATION: ${changeMotivation}`,
        successCallback: () => {
          () => {
            mixpanel.lessonAction('ChangeCoach: Cancelled', {
              firstLessonNumber: 0,
              totalLessons: 0,
              cancellationMotive: `CHANGE MOTIVATION: ${changeMotivation}`,
              type: 'change-coach'
            });
          };
        },
        callback
      });
    }
  };

  const handleGoToNextStep = () => {
    setChangeCoachMotivationAction(changeCoachMotivation);

    executeAppointmentCancelation(changeCoachMotivation, () => {
      setCurrentStep((prevState) => prevState + 1);
    });
  };

  const handleOnSuccess = () => {
    initChangeCoachProcessAction(false);
    setChangeCoachSuccessAction(true);
  };

  const handleCloseModal = () => {
    setOpenChangeCoachModalAction(false);
  };

  const handleUserTypingMotive = (evt) => {
    setStateCoachMotivation(evt.target.value);
  };

  const stepContent = {
    0: (
      <>
        <h1>Changing coaches</h1>
        <div className={styles.changeCoachSummary}>
          <div className={styles.bookingSummaryList}>
            <p className={styles.textareaLabel}>Before booking with your new coach, please tell us why you would like to change.</p>
            <textarea
              className={styles.motivationTextarea}
              rows={5}
              value={changeCoachMotivation}
              onChange={handleUserTypingMotive}
              placeholder="Your answer will only be visible to our internal team, never to your current coach."
            />
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button outfit="secondaryButton" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            loading={isCancellingAppointment || isLoadingLessonsStatus}
            outfit="regularButton"
            onClick={handleGoToNextStep}
            disabled={!changeCoachMotivation}>
            Next
          </Button>
        </div>
      </>
    ),
    1: <BookingTuitionSessionModal redirectTo="/bookings" handleOnClose={handleCloseModal} handleOnSuccess={handleOnSuccess} />
  };

  const setModalProgress = () => {
    const stepsLength = Object.keys(stepContent).length;
    const currentStepPositionSerialized = currentStep + 1;
    setCompletionProgress((currentStepPositionSerialized / stepsLength) * 100);
  };

  useEffect(() => {
    setModalProgress();
  }, [currentStep]);

  return (
    <>
      <Modal
        completionProgress={completionProgress}
        loading={isCreatingAppointment}
        content={<div className={styles.changeCoachModalWrapper}>{stepContent[currentStep]}</div>}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  bookingFutureSchedule: state.bookable.futureSchedule.futureSchedule,
  conversationClassesStatus: state.sessions.conversationSessions.currentLessonsStatus,
  tuitionLessonsStatus: state.sessions.tuitionSessions.currentLessonsStatus,
  isCancellingAppointment: state.newBooking.canceling.isCancelingAppointment,
  isCreatingAppointment: state.newBooking.bookingLessons.isCreatingAppointment,
  isLoadingLessonsStatus: state.sessions.tuitionSessions.isLoadingLessonsStatus,
  selectedCourseId: state.courses?.selectedCourseDetails?.courseDetails?.id,
  student: state.user
});

ChangeCoachModal.propTypes = {
  cancelMultipleBookingsAndGetUpdatedStudentsBookingAction: PropTypes.func.isRequired,
  conversationClassesStatus: PropTypes.object.isRequired,
  tuitionLessonsStatus: PropTypes.object.isRequired,
  initChangeCoachProcessAction: PropTypes.func.isRequired,
  isCancellingAppointment: PropTypes.bool.isRequired,
  isCreatingAppointment: PropTypes.bool.isRequired,
  isLoadingLessonsStatus: PropTypes.bool.isRequired,
  selectedCourseId: PropTypes.number.isRequired,
  setChangeCoachMotivationAction: PropTypes.func.isRequired,
  setChangeCoachSuccessAction: PropTypes.func.isRequired,
  setOpenChangeCoachModalAction: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  cancelMultipleBookingsAndGetUpdatedStudentsBookingAction: cancelMultipleBookingsAndGetUpdatedStudentsBooking,
  createAppointmentAndRedirectToAnotherPageAction: createAppointmentAndRedirectToAnotherPage,
  getFutureScheduleAction: getFutureSchedule,
  initChangeCoachProcessAction: initChangeCoachProcess,
  setChangeCoachMotivationAction: setChangeCoachMotivation,
  setChangeCoachSuccessAction: setChangeCoachSuccess,
  setOpenChangeCoachModalAction: setOpenChangeCoachModal
})(ChangeCoachModal);
