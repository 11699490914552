import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import { Button } from 'react-bootstrap';
import Modal from '../../components/uikit/Modal.js';
import uiStyles from '../../style/components/uikit/ButtonModal.module.scss';
import styles from '../../style/components/appointment-list/AppointmentTable.module.scss';
import sendEvent from '../../actions/events.js';
import { getCityTimezone } from '../../utils/time-helpers.js';

class AppointmentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderTeamsModal: false
    };
  }

  joinChat = () => {
    const { selectedAppointment } = this.state;
    const url = `/chat/${selectedAppointment.chat}`;
    const chatPage = window.open(url, '_blank');
    chatPage.focus();
  };

  buildJoinLink(url, text, appointmentId) {
    const { token } = this.props;

    return (
      <a
        href={url}
        className={styles.join}
        onClick={() => this.props.sendEventAction(token, { eventType: 'joined_video', appointmentId })}
        target="_blank"
        without
        rel="noopener noreferrer">
        {text}
      </a>
    );
  }

  renderProperJoinLink(data, row) {
    // eslint-disable-next-line
    const preferredClassroom = data[row._index].preferred_classroom;
    // eslint-disable-next-line
    const appointmentId = data[row._index].classroom;

    if (!preferredClassroom) {
      return (
        <>
          <a href="mailto:community@chatterbox.io">Contact us</a>
        </>
      );
    }

    // eslint-disable-next-line
    if (preferredClassroom === 'meetme' && data[row._index].meetme_url)
      return this.buildJoinLink(`${data[row._index].meetme_url}`, 'Join session', appointmentId);

    // eslint-disable-next-line
    if (preferredClassroom === 'zoom' && data[row._index].zoomid)
      return this.buildJoinLink(`${data[row._index].zoomid}`, 'Join Zoom session', appointmentId);

    // eslint-disable-next-line
    if (preferredClassroom === 'webex' && data[row._index].webex_link)
      return this.buildJoinLink(`${data[row._index].webex_link}`, 'Join Webex session', appointmentId);

    if (preferredClassroom === 'teams' && data[row._index].teams)
      return this.buildJoinLink(`${data[row._index].teams}`, 'Join Microsoft Teams session', appointmentId);
  }

  renderTeamsModal() {
    return (
      <Modal
        className={uiStyles.modal}
        withCloseButton={false}
        onModalClose={() => {
          this.setState({ renderTeamsModal: false });
        }}>
        <div>
          <p>
            Your learner prefers to meet on Microsoft Teams. You should receive an invitation link before live practice via <a href="/chat">Chat</a>
          </p>
          <div className={styles.buttonWrapper}>
            <button
              onClick={() => {
                this.setState({ renderTeamsModal: false, selectedAppointment: undefined });
              }}
              className={styles.closeButton}>
              Close
            </button>

            <Button className={styles.joinVideoButton} bsStyle="primary" onClick={this.joinChat}>
              Go to Chat
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { data, defaultSorting, timezone } = this.props;

    const defaultPageSize = data.length > 19 ? 20 : data.length;

    return (
      <>
        {this.state.renderTeamsModal && this.renderTeamsModal()}
        <ReactTable
          data={data}
          columns={[
            {
              Header: `Date and time ${getCityTimezone(timezone)}`,
              accessor: 'date',
              minWidth: 250,
              headerClassName: styles.tableHeader,
              className: styles.tableRow
            },
            {
              Header: 'Appointment Type',
              accessor: 'appointmentType',
              minWidth: 150,
              headerClassName: styles.tableHeader,
              className: styles.tableRow
            },
            {
              Header: 'Student(s)',
              accessor: 'students',
              minWidth: 150,
              headerClassName: styles.tableHeader,
              className: styles.tableRow
            },
            {
              Header: 'Status',
              accessor: 'status',
              headerClassName: styles.tableHeader,
              className: styles.tableRow,
              minWidth: 100
            },
            {
              Header: 'Classroom',
              accessor: 'classroom',
              // eslint-disable-next-line
              Cell: ({ row }) => this.renderProperJoinLink(data, row),
              headerClassName: styles.tableHeader,
              className: styles.tableRow,
              minWidth: 100
            },
            {
              Header: 'Resources',
              accessor: 'resources',
              // eslint-disable-next-line
              Cell: ({ row }) => {
                // eslint-disable-next-line
                if (data[row._index].courseId) {
                  return (
                    // eslint-disable-next-line
                    <Link
                      className={styles['see-resources']}
                      to={{ pathname: `/resources/${data[row._index].courseId}/${data[row._index].lessonNumber}` }}>
                      See resources
                    </Link>
                  );
                }
                return null;
              },
              headerClassName: styles.tableHeader,
              className: styles.tableRow,
              minWidth: 100
            }
          ]}
          defaultSorted={[
            {
              id: 'date',
              desc: defaultSorting
            }
          ]}
          defaultPageSize={defaultPageSize}
          className="-striped -highlight"
        />
      </>
    );
  }
}

function mapStateToProps() {
  return {};
}

AppointmentTable.propTypes = {
  data: PropTypes.array.isRequired,
  defaultSorting: PropTypes.bool,
  sendEventAction: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

AppointmentTable.defaultProps = {
  defaultSorting: false
};

export default connect(mapStateToProps, {
  sendEventAction: sendEvent
})(AppointmentTable);
