import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isToday, isTomorrow } from 'date-fns';
import { connect } from 'react-redux';

import Tooltip from '../../../../design-system/tooltip/Tooltip';
import Button from '../../../../design-system/button/Button';
import CardWithHeader from '../../../../design-system/card/card-with-header/CardWithHeader';

import styles from './LivePracticeCard.module.scss';
import {
  convertStringsOfDateAndTimeToDate,
  convertToLocaleAndFormat,
  getDiffHoursBetweenNowAndDate,
  getDiffMinutesBetweenNowAndDate
} from '../../../../../utils/time-helpers';

import {
  addToCancelingList,
  addToReschedulingList,
  setOpenBookingIntroClassModal,
  setOpenCancelingModal,
  setOpenReschedulingModal
} from '../../../../../actions/new-booking';
import { setSelectedLanguage } from '../../../../../actions/selected-language';
import { openInNewWindow } from '../../../../../utils/url-helpers';
import VideoClassModal from '../../../../new-booking/MyBookings/components/VideoClassModal';
import { setSelectedTutor } from '../../../../../actions/selected-tutor';

const IntroClassCard = ({
  addToCancelingListAction,
  addToReschedulingListAction,
  appt,
  isGettingCourseDetails,
  isGettingEnrolledCourses,
  isGettingIntroClass,
  setOpenBookingIntroClassModalAction,
  setOpenCancelingModalAction,
  setOpenReschedulingModalAction,
  setSelectedTutorAction,
  timezone,
  user
}) => {
  const [diff, setDiff] = useState(0);
  const [isApptWithin24H, setIsApptWithin24H] = useState(false);
  const [isApptTomorrow, setIsApptTomorrow] = useState(false);
  const [isApptToday, setIsApptToday] = useState(false);
  const [isTimeToClass, setIsTimeToClass] = useState(false);
  const [isVideoInstructionModalOpen, setIsVideoInstructionModalOpen] = useState(false);

  const getDifferenceInDays = (appt) => {
    if (!appt?.date || !appt?.start_time) return;
    const date = convertStringsOfDateAndTimeToDate(appt.date, appt.start_time);
    const diffHours = getDiffHoursBetweenNowAndDate(date);
    const diffMinutes = getDiffMinutesBetweenNowAndDate(date);

    if (diffHours > 24) {
      setDiff(Math.floor(diffHours / 24));
      if (isTomorrow(date)) setIsApptTomorrow(true);
    } else {
      setIsApptWithin24H(true);
      if (isTomorrow(date)) setIsApptTomorrow(true);
      if (isToday(date)) setIsApptToday(true);
      if (Math.abs(diffMinutes) < 15) setIsTimeToClass(true);
    }
  };

  useEffect(() => {
    if (!appt || !Object.keys(appt).length) return;
    getDifferenceInDays(appt);
  }, [appt]);

  const history = useHistory();

  const handleSingleCardRescheduling = () => {
    if (!appt?.date || !appt?.tutor || !appt?.id) return;
    setSelectedTutorAction(appt.tutor);
    addToReschedulingListAction(appt);
    setOpenReschedulingModalAction();
  };

  const handleSingleCardCancelation = () => {
    if (!appt?.date || !appt?.id) return;
    addToCancelingListAction({ ...appt, lateCanceling: isApptWithin24H });
    setOpenCancelingModalAction();
  };

  const availableOptions = () => {
    if (isApptWithin24H)
      return [
        <Button key="Cancel session" outfit="thirdButton" testId="cancel-intro-session" onClick={handleSingleCardCancelation}>
          Cancel session
        </Button>
      ];

    return [
      <Button key="Reschedule" outfit="secondaryButton" testId="reschedule-intro-session" onClick={handleSingleCardRescheduling}>
        Reschedule
      </Button>,
      <Button key="Cancel session" outfit="thirdButton" testId="cancel-intro-session" onClick={handleSingleCardCancelation}>
        Cancel session
      </Button>
    ];
  };

  const handlePushToTutorProfile = () => {
    if (!appt?.tutor?.id) return;
    history.push(`../tutor-profile/${appt?.tutor?.id}`);
  };

  if (!appt?.date)
    return (
      <CardWithHeader
        title="2. Meet Your Coach session"
        isLoading={isGettingIntroClass || isGettingEnrolledCourses || isGettingCourseDetails}
        onClick={() => setOpenBookingIntroClassModalAction(true)}
        type="black"
        testId="intro-class-card"
        cardContent={
          <div className={styles['live-practice-card-only-text']} data-testid="book-session">
            <h2>Book</h2>
            <p>your Meet Your Coach session</p>
          </div>
        }
      />
    );

  if (isTimeToClass) {
    const getOnlineClassUrl = (tutor, classPlatform) => {
      if (classPlatform === 'zoom') return tutor.zoom_id;
      if (classPlatform === 'webex') return tutor.webex_link;

      return null;
    };

    const handleJoinClass = () => {
      const onlineClassUrl = getOnlineClassUrl(appt?.tutor, user.preferred_class);
      if (onlineClassUrl) return openInNewWindow(onlineClassUrl);
      return setIsVideoInstructionModalOpen(true);
    };

    return (
      <>
        <CardWithHeader
          title="2. Meet Your Coach session"
          isLoading={isGettingIntroClass || isGettingEnrolledCourses || isGettingCourseDetails}
          type="black"
          testId="intro-class-card"
          cardContent={
            <button className={styles['live-practice-card-only-text']} data-testid="join-now" onClick={handleJoinClass}>
              <h2>Join</h2>
              <p>{appt?.tutor?.first_name} now</p>
            </button>
          }
        />
        {isVideoInstructionModalOpen && <VideoClassModal preferredClass={user.preferred_class} tutor={appt?.tutor} />}
      </>
    );
  }

  if (isApptToday)
    return (
      <CardWithHeader
        title="2. Meet Your Coach session"
        isLoading={isGettingIntroClass || isGettingEnrolledCourses || isGettingCourseDetails}
        type="black"
        options={availableOptions()}
        testId="intro-class-card"
        cardContent={
          <div className={styles['live-practice-card']} data-testid="is-today">
            <div
              className={[styles['coach-picture'], styles['clickable']].join(' ')}
              role="button"
              data-testid="is-today-button"
              onClick={handlePushToTutorProfile}>
              <img src={appt?.tutor.profile_picture} alt="tutor" />
            </div>
            <div className={styles['date-wrapper']}>
              <h2>is today</h2>
              <p>{convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.start_time), timezone)}</p>
            </div>
            <div className={styles.tooltip}>
              <Tooltip
                tooltipLabel="You will be able to enter the Meet Your Coach session up to 15 minutes before it starts."
                backgroundColor="white"
              />
            </div>
          </div>
        }
      />
    );

  if (isApptTomorrow)
    return (
      <CardWithHeader
        title="2. Meet Your Coach session"
        isLoading={isGettingIntroClass || isGettingEnrolledCourses || isGettingCourseDetails}
        type="black"
        options={availableOptions()}
        testId="intro-class-card"
        cardContent={
          <div className={styles['live-practice-card']} data-testid="is-tomorrow">
            <div
              className={[styles['coach-picture'], styles['clickable']].join(' ')}
              role="button"
              data-testid="is-tomorrow-button"
              onClick={handlePushToTutorProfile}>
              <img src={appt?.tutor.profile_picture} alt="tutor" />
            </div>
            <div className={styles['date-wrapper']}>
              <h2>is tomorrow</h2>
              <p>{convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.start_time), timezone)}</p>
            </div>
            <div className={styles.tooltip}>
              <Tooltip
                tooltipLabel="You will be able to enter the Meet Your Coach session up to 15 minutes before it starts."
                backgroundColor="white"
              />
            </div>
          </div>
        }
      />
    );

  return (
    <CardWithHeader
      title="2. Meet Your Coach session"
      isLoading={isGettingIntroClass || isGettingEnrolledCourses || isGettingCourseDetails}
      type="black"
      options={availableOptions()}
      testId="intro-class-card"
      cardContent={
        <div className={styles['live-practice-card']} data-testid={`in-${diff}-days`}>
          <div
            className={[styles['coach-picture'], styles['clickable']].join(' ')}
            role="button"
            data-testid={`in-${diff}-days-button`}
            onClick={handlePushToTutorProfile}>
            <img src={appt?.tutor.profile_picture} alt="tutor" />
          </div>
          <div className={styles['date-wrapper']}>
            <div className={styles['count-wrapper']}>
              <h2>in </h2>
              <span id="dont-translate">{diff}</span>
              <h2>{diff > 1 ? ' days' : ' day'}</h2>{' '}
            </div>
            <p>{convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.start_time), timezone)}</p>
          </div>
          <div className={styles.tooltip}>
            <Tooltip
              tooltipLabel="You will be able to enter the Meet Your Coach session up to 15 minutes before it starts."
              backgroundColor="white"
            />
          </div>
        </div>
      }
    />
  );
};

IntroClassCard.propTypes = {
  addToCancelingListAction: PropTypes.func.isRequired,
  addToReschedulingListAction: PropTypes.func.isRequired,
  appt: PropTypes.object,
  activeOrders: PropTypes.array,
  isGettingCourseDetails: PropTypes.bool,
  isGettingEnrolledCourses: PropTypes.bool,
  isGettingIntroClass: PropTypes.bool,
  setOpenBookingIntroClassModalAction: PropTypes.func,
  setOpenCancelingModalAction: PropTypes.func.isRequired,
  setOpenReschedulingModalAction: PropTypes.func.isRequired,
  setSelectedTutorAction: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  tutorProfilePicture: PropTypes.string,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  activeOrders: state.orderInfo?.active_orders,
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isGettingEnrolledCourses: state.courses.enrolledCourses.isGettingEnrolledCourses,
  isGettingIntroClass: state.sessions.introSession.isGettingIntroClass,
  timezone: state.time.timezone,
  user: state.user
});

export default connect(mapStateToProps, {
  setOpenBookingIntroClassModalAction: setOpenBookingIntroClassModal,
  setSelectedLanguageAction: setSelectedLanguage,
  addToCancelingListAction: addToCancelingList,
  addToReschedulingListAction: addToReschedulingList,
  setOpenReschedulingModalAction: setOpenReschedulingModal,
  setOpenCancelingModalAction: setOpenCancelingModal,
  setSelectedTutorAction: setSelectedTutor
})(IntroClassCard);
