import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './UserPossibilities.module.scss';
import LoadingSpinner from '../design-system/loading/spinner/LoadingSpinner';
import Button from '../design-system/button/Button';

import { ReactComponent as IconList } from '../../assets/icons/icon_list.svg';
import { useHistory, useRouteMatch } from 'react-router-dom';

const UserPossibilities = ({ isGettingTutorProfile }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  if (isGettingTutorProfile)
    return (
      <div className={styles['loading-wrapper']}>
        <LoadingSpinner />
      </div>
    );

  return (
    <aside className={styles['aside-container']}>
      <section className={styles['options']}>
        <div className={styles['option']}>
          <Button outfit={'roundedMainButton'}>
            <IconList />
            View today&apos;s appointments
          </Button>
        </div>
        <div className={styles['option']}>
          <Button onClick={() => history.push(`${url}/past-appointments`)} outfit={'roundedTransparentButton'}>
            <IconList />
            View past appointments
          </Button>
        </div>
        <div className={styles['option']}>
          <Button onClick={() => history.push(`${url}/future-appointments`)} outfit={'roundedTransparentButton'}>
            <IconList />
            View future appointments
          </Button>
        </div>
      </section>
    </aside>
  );
};

UserPossibilities.propTypes = {
  isGettingTutorProfile: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isGettingTutorProfile: state.tutorProfile.isGettingTutorProfile
});

export default connect(mapStateToProps, {})(UserPossibilities);
