import React, { useEffect, useState } from 'react';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../design-system/button/Button';
import CardLoader from '../../../design-system/loading/card/CardLoader';

import { addToCancelingList, addToReschedulingList, setOpenCancelingModal, setOpenReschedulingModal } from '../../../../actions/new-booking';

import { isCardAlreadySelectedSelected, selecAppointmentsToBeCanceledOrRescheduled } from '../../../../reducers/new-booking';

import styles from './LessonAppointmentCard.module.scss';

import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat, formatDateForCalendr } from '../../../../utils/time-helpers';
import Mixpanel from '../../../../utils/mixpanel-helper.js';
import { openInNewWindow } from '../../../../utils/url-helpers';
import VideoClassModal from './VideoClassModal';

import IconInfoGray from '../../../../assets/icons/icon_info-gray.svg';
import { setSelectedTutor } from '../../../../actions/selected-tutor';
import AppointmentTag from './AppointmentTag';
import { BULK_OPTIONS, CLASS_TYPES } from '../../Enum';

const joinButtonLabel = Object.freeze({
  zoom: 'Join on Zoom',
  teams: 'How to join',
  webex: 'Join on Webex'
});

const MINUTES_RANGE_TO_DISPLAY_JOIN = 15;

const TuitionLessonAppointmentCard = ({
  addToReschedulingListAction,
  addToCancelingListAction,
  isLoading,
  isPast,
  lesson,
  isBulkingState,
  preferredClass,
  reschedulingType,
  selectedAction,
  setOpenCancelingModalAction,
  setOpenReschedulingModalAction,
  setSelectedTutorAction,
  timezone
}) => {
  const [isWithin24H, setIsWithin24H] = useState(false);
  const [isClassReadyToStart, setIsClassReadyToStart] = useState(false);
  const [singleSelection, setSingleSelection] = useState(false);
  const [isVideoInstructionModalOpen, setIsVideoInstructionModalOpen] = useState(false);
  const appointmentsToBeCanceledOrRescheduled = useSelector((state) => selecAppointmentsToBeCanceledOrRescheduled(state, selectedAction)) || [];
  const history = useHistory();

  const selected = useSelector(() => isCardAlreadySelectedSelected(appointmentsToBeCanceledOrRescheduled, lesson));

  const handleCardSelection = () => {
    if (selectedAction === BULK_OPTIONS.cancel) addToCancelingListAction(lesson);
    if (selectedAction === BULK_OPTIONS.reschedule) addToReschedulingListAction(lesson);
  };

  const handleSingleCardRescheduling = () => {
    setSelectedTutorAction(lesson?.appointment_object.tutor);
    setSingleSelection(false);
    setOpenReschedulingModalAction();
    addToReschedulingListAction(lesson);
  };

  const handleSingleCardCancelation = () => {
    setSingleSelection(false);
    addToCancelingListAction({ ...lesson, lateCanceling: isWithin24H });
    setOpenCancelingModalAction();
  };

  const buttonDependingOnTime = (is24HFromNow) => {
    if (!canBeBulkSelected) {
      return (
        <div className={styles.infoIconWrapper}>
          <img className={styles.infoIcon} src={IconInfoGray} alt="information icon" />
          <p className={styles.tooltipContent}>
            You can only bulk select one type of session per time. After rescheduling the conversation sessions, you can select the tuition ones.
          </p>
        </div>
      );
    }

    if (is24HFromNow) {
      return (
        <div className={styles.infoIconWrapper}>
          <img className={styles.infoIcon} src={IconInfoGray} alt="information icon" />
          <p className={styles.tooltipContent}>This lesson is in less than 24h. You can only manage it individually after exiting.</p>
        </div>
      );
    }

    return <button className={[styles.checkButton, selected && styles.selected].join(' ')} />;
  };

  const checkWhetherLessonIsWithin24H = () => {
    if (!lesson?.appointment_object) return;
    const appointmentISODate = `${lesson?.appointment_object.date}T${lesson?.appointment_object.start_time}:00Z`;
    const hoursUntilAppointment = differenceInHours(new Date(appointmentISODate), new Date());
    if (hoursUntilAppointment < 24) setIsWithin24H(true);
  };

  const checkWheterClassIsReadyToStart = () => {
    if (!lesson?.appointment_object) return;
    const appointmentISODate = `${lesson?.appointment_object.date}T${lesson?.appointment_object.start_time}:00Z`;
    const minutesUntilAppointment = differenceInMinutes(new Date(appointmentISODate), new Date());
    if (Math.abs(minutesUntilAppointment) <= MINUTES_RANGE_TO_DISPLAY_JOIN) setIsClassReadyToStart(true);
  };

  const getOnlineClassUrl = (tutor, classPlatform) => {
    if (classPlatform === 'zoom') return tutor.zoom_id;
    if (classPlatform === 'webex') return tutor.webex_link;

    return null;
  };

  const handleJoinClass = () => {
    Mixpanel.track('Click', { button: 'join_video' });

    const { appointment_object: appointmentObject } = lesson;
    if (!appointmentObject.tutor) return;

    const onlineClassUrl = getOnlineClassUrl(appointmentObject.tutor, preferredClass);
    if (onlineClassUrl) return openInNewWindow(onlineClassUrl);

    return setIsVideoInstructionModalOpen(true);
  };

  const createCalendarLinkUrl = ({ startTime, endTime, tutor, classPlatform, lessonNumber }) => {
    const baseUrl =
      'https://calndr.link/d/event/?service=google&' +
      `title=Online Lesson ${lessonNumber}` +
      `+with+${tutor.first_name}` +
      `&start=${startTime}&end=${endTime}` +
      `&timezone=${timezone}`;

    if (classPlatform === 'meetme') {
      return (
        `${baseUrl}&description=From Skype: go to the Skype search bar and look for "Meet Me"<br />` +
        'then make a Skype Call (for audio only) or video call (for audio and video)<br />' +
        'use the keyboard (icon on the bottom right corner) to dial the' +
        'conference ID and guest code.'
      );
    }

    if (classPlatform === 'skype') {
      return `${baseUrl}&description=Please add your tutor on Skype, their Skype ID is: ${tutor.skype_id}`;
    }

    if (classPlatform === 'zoom' || classPlatform === 'webex') {
      return (
        `${baseUrl}&description=Connect to your Live Class here: ` +
        `<a href=${getOnlineClassUrl(tutor, classPlatform)}>` +
        `${getOnlineClassUrl(tutor, classPlatform)}</a><br />`
      );
    }

    if (classPlatform === 'teams') {
      return `${baseUrl}&description=Please invite your coach on Teams. Here is their email: ${tutor.teams_email}.`;
    }

    return baseUrl;
  };

  const handleAddToCalendar = () => {
    const startTime = formatDateForCalendr(convertStringsOfDateAndTimeToDate(lesson?.appointment_object.date, lesson?.appointment_object.start_time));
    const endTime = formatDateForCalendr(convertStringsOfDateAndTimeToDate(lesson?.appointment_object.date, lesson?.appointment_object.end_time));

    const url = createCalendarLinkUrl({
      startTime,
      endTime,
      tutor: lesson?.appointment_object.tutor,
      classPlatform: preferredClass,
      lessonNumber: lesson?.number
    });

    return openInNewWindow(url);
  };

  useEffect(() => {
    if (lesson && lesson?.appointment_object) {
      checkWhetherLessonIsWithin24H();
      checkWheterClassIsReadyToStart();
    }
  }, [lesson]);

  const showDateOrJoinButton = (isReady) => {
    if (isReady) {
      return (
        <div className={styles.joinClassButtonWrapper}>
          <Button onClick={handleJoinClass}>{joinButtonLabel[preferredClass] || 'Join Classroom'}</Button>
        </div>
      );
    }
    return (
      <div className={styles.cardFooter}>
        {lesson?.appointment_object.tutor.profile_picture && (
          <div className={styles.tutorImage}>
            <button onClick={() => history.push(`../tutor-profile/${lesson?.appointment_object.tutor.id}`)}>
              <img src={lesson?.appointment_object.tutor.profile_picture} alt="Tutor" />
            </button>
          </div>
        )}
        <div className={styles.timestamp}>
          <p id="dont-translate">
            {convertToLocaleAndFormat(new Date(`${lesson?.appointment_object.date}T${lesson?.appointment_object.start_time}:00Z`), timezone)}-
            {convertToLocaleAndFormat(
              new Date(`${lesson?.appointment_object.date}T${lesson?.appointment_object.end_time}:00Z`),
              timezone,
              'hh:mm aa'
            )}
            <p className={styles.timezoneStamp}>({timezone})</p>
          </p>
        </div>
      </div>
    );
  };

  const canBeBulkSelected = selectedAction !== BULK_OPTIONS.reschedule ? true : reschedulingType ? reschedulingType === CLASS_TYPES.tuition : true;

  const showBulkingStageOrDots = (isBulkingStage) => {
    if (isBulkingStage) return buttonDependingOnTime(isWithin24H);
    return (
      <button className={styles.threeDots} onClick={() => setSingleSelection(true)} data-testid="single-three-dots">
        <div className={styles.oneDot} />
        <div className={styles.oneDot} />
        <div className={styles.oneDot} />
      </button>
    );
  };

  return (
    <div
      className={[
        styles.cardWrapper,
        isPast && styles.isPast,
        isBulkingState && styles.selectable,
        selected && styles.selected,
        singleSelection && styles.singleSelectionIsOn
      ].join(' ')}
      role="button"
      data-testid="tuition-session-appointment-card"
      onClick={isBulkingState && !isWithin24H && !singleSelection && canBeBulkSelected ? handleCardSelection : undefined}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <CardLoader rows={4} rowsColor="gray" />
        </div>
      ) : (
        <>
          <div className={styles.cardContent}>
            <div className={styles.cardInformation}>
              {!isPast && <AppointmentTag appointmentTag={CLASS_TYPES.tuition} />}
              {Boolean(lesson?.number) && (
                <div className={styles.title}>
                  <h1>
                    Lesson <span id="dont-translate">{lesson.number}</span>
                  </h1>
                  {Boolean(lesson?.name) && <h2 id="dont-translate">{lesson?.name}</h2>}
                </div>
              )}
              {showDateOrJoinButton(isClassReadyToStart)}
            </div>
            {singleSelection && (
              <div className={styles.singleSelectionOptions}>
                <Button onClick={() => setSingleSelection(false)}>X</Button>
                <div className={styles.buttonsWrapper}>
                  {!isWithin24H && (
                    <Button outfit="secondaryButton" onClick={handleSingleCardRescheduling} testId={`single-three-dots-reschedule`}>
                      Reschedule
                    </Button>
                  )}
                  <Button outfit="thirdButton" onClick={handleSingleCardCancelation} testId={`single-three-dots-cancel`}>
                    Cancel session
                  </Button>
                  <Button outfit="secondaryButton" onClick={handleAddToCalendar} testId={`single-three-dots-add-to-calendar`}>
                    Add to Calendar
                  </Button>
                </div>
              </div>
            )}
          </div>
          {showBulkingStageOrDots(isBulkingState)}
          {isVideoInstructionModalOpen && <VideoClassModal preferredClass={preferredClass} tutor={lesson?.appointment_object.tutor} />}
        </>
      )}
    </div>
  );
};

TuitionLessonAppointmentCard.propTypes = {
  addToReschedulingListAction: PropTypes.func.isRequired,
  addToCancelingListAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isPast: PropTypes.bool,
  lesson: PropTypes.object,
  isBulkingState: PropTypes.bool,
  preferredClass: PropTypes.string.isRequired,
  reschedulingType: PropTypes.string,
  selectedAction: PropTypes.string,
  setOpenCancelingModalAction: PropTypes.func.isRequired,
  setOpenReschedulingModalAction: PropTypes.func.isRequired,
  setSelectedTutorAction: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
};

TuitionLessonAppointmentCard.defaultProps = {
  isLoading: false,
  isPast: false,
  isBulkingState: false
};

const mapStateToProps = (state) => ({
  isBulkingState: state.newBooking.generic.isBulkingState,
  preferredClass: state.user.preferred_class,
  reschedulingType: state.newBooking.rescheduling.sessionType,
  selectedAction: state.newBooking.generic.selectedAction,
  timezone: state.time.timezone
});

export default connect(mapStateToProps, {
  addToReschedulingListAction: addToReschedulingList,
  addToCancelingListAction: addToCancelingList,
  setOpenReschedulingModalAction: setOpenReschedulingModal,
  setOpenCancelingModalAction: setOpenCancelingModal,
  setSelectedTutorAction: setSelectedTutor
})(TuitionLessonAppointmentCard);
