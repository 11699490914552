import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CardLoader from '../../../design-system/loading/card/CardLoader';
import Button from '../../../design-system/button/Button';
import ExpandableDescription from './ExpandableDescription';
import Tip from './Tip';

import styles from './CoachCard.module.scss';
import { PERIOD_OF_THE_DAY } from '../../../availability/helpers';
import DescriptionModal from './DescriptionModal';

const CoachCard = ({ index, tutor, isLoading, onBook }) => {
  const [readMore, setReadMore] = useState(false);
  if (isLoading)
    return (
      <div className={[styles['card-wrapper'], styles.loading].join(' ')}>
        <CardLoader rows={4} rowsColor="gray" />
      </div>
    );

  const checkCoachAvailability = () => {
    if (!tutor?.availability_matrix || !tutor?.availability_matrix?.length) return [];
    return tutor.availability_matrix.reduce((acc, cv, index) => {
      const isAvailable = cv.some((value) => value);
      if (isAvailable) return [...acc, PERIOD_OF_THE_DAY[index]];
      return [...acc];
    }, []);
  };

  return (
    <>
      <div role="button" className={styles['card-wrapper']} onClick={() => setReadMore(true)}>
        <div className={styles['coach-presentation']}>{tutor?.photo && <img src={tutor?.photo} alt="" />}</div>
        <h3 className={styles['coach-name']} id={`coach-name-${index}`}>{`${tutor?.first_name} ${tutor?.last_name}`}</h3>
        <div className={styles['coach-availability']}>
          {checkCoachAvailability().map((av, index) => (
            <Tip key={index} label={av} />
          ))}
        </div>
        <div className={styles['coach-description']}>
          <ExpandableDescription openReadMore={() => setReadMore(true)} readMore={readMore} tutor={tutor} />
        </div>
        <div className={styles['book-button']}>
          <Button bold outfit="greenButton" onClick={() => onBook({ ...tutor })} id={`coach-${index}`}>
            BOOK
          </Button>
        </div>
        {typeof tutor?.isTutorAvailable === 'boolean' && !tutor?.isTutorAvailable && (
          <div className={styles['card-unavailability-tag']}>
            <p>Availability not matching</p>
          </div>
        )}
      </div>
      <DescriptionModal
        availability={checkCoachAvailability()}
        isModalOpen={readMore}
        onBook={onBook}
        onCloseModal={() => setReadMore(false)}
        tutor={tutor}
      />
    </>
  );
};

CoachCard.propTypes = {
  availability: PropTypes.array,
  index: PropTypes.number,
  tutor: PropTypes.object,
  isLoading: PropTypes.bool,
  onBook: PropTypes.func
};

export default CoachCard;
