import React, { Component } from 'react';
import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getInvoiceMonthAppointments, resetAppointments } from '../actions/appointments';
import { createInvoice, getInvoices } from '../actions/invoices';
import InvoiceTable from '../components/invoices/InvoiceTable';
import SortCodeInput from '../components/invoices/SortCodeInput';
import ButtonModal from '../components/uikit/ButtonModal';
import NumberIcon from '../components/uikit/NumberIcon';

import styles from '../style/containers/Invoices.module.scss';
import appStyles from '../style/containers/App.module.scss';

import IconCheck from '../assets/icons/icon_check.svg';
import IconWarning from '../assets/icons/icon_warning.svg';

import { EMAIL_REG_EXP, POSTCODE_REG_EXP } from '../utils/form-helpers';
import { getSnakeCaseObject } from '../utils/object-helpers';
import Button from '../components/design-system/button/Button';
import ErrorMessage from '../components/design-system/helper-messages/error-message/ErrorMessage';

const ALLOWED_INVOICING_START_DAY_OF_MONTH = 1;
const ALLOWED_INVOICING_END_DAY_OF_MONTH = 20;

class Invoices extends Component {
  state = {
    errorMessage: null,
    invoiceWasSent: false,
    isLoading: false,
    userIsInUk: true,
    userIsInEu: false,
    userIsInUs: false,
    userIsInternational: false,
    paypal: false,

    addressLine1: '',
    addressLine2: '',
    addressTown: '',
    addressCounty: '',
    addressPostcode: '',

    paypalAddress: '',

    paymentName: '',
    paymentBank: '',
    paymentAccountNumber: '',
    paymentSortCode: '',
    iban: '',
    bic: '',
    bankNumber: '',
    routing: '',
    branchCode: '',

    paypalEmail: ''
  };

  UNSAFE_componentWillMount() {
    this.props.resetAppointments();
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.getInvoices(user.token);
    this.props.getInvoiceMonthAppointments(user.token, user.id);
  }

  onBlur = () => this.forceUpdate();
  onSortCodeFocus = () => this.forceUpdate();

  getLastInvoicedPeriodStart = (invoices) => {
    if (invoices.length) {
      const checkInvoiceOrder = (invoice1, invoice2) => moment.utc(invoice1.period_start_date) - moment.utc(invoice2.period_start_date);

      const lastInvoice = invoices.sort(checkInvoiceOrder)[invoices.length - 1];
      return moment.utc(lastInvoice.period_start_date);
    }
  };

  checkIfInvoicingAllowedToday = (now, lastInvoicedPeriodStart = undefined) => {
    const thisDayOfMonth = now.date();

    if (thisDayOfMonth > ALLOWED_INVOICING_END_DAY_OF_MONTH) {
      return false;
    }

    if (lastInvoicedPeriodStart) {
      const lastMonthStart = now.clone().date(1).subtract(1, 'month');

      if (lastInvoicedPeriodStart.isSame(lastMonthStart, 'day')) {
        return false;
      }
    }

    return true;
  };

  doNothing = (event) => event.preventDefault();

  isFocused = (id) => {
    const node = document.getElementById(id);
    return document.activeElement === node;
  };

  sortCodeIsFocused = () => {
    // eslint-disable-next-line no-unused-vars
    for (const index of [1, 2, 3]) {
      const node = document.getElementById(`sort-code-${index}`);

      if (document.activeElement === node) {
        return true;
      }
    }

    return false;
  };

  buttonModal = null;

  handleChange = (event) => {
    let value = event.target.value;

    if (event.target.id === 'addressPostcode') {
      value = value.toUpperCase();
    }

    if (event.target.id === 'paymentAccountNumber') {
      const isNumbers = /^[0-9]*$/.test(value);

      if (!isNumbers || value.length > 8) {
        this.forceUpdate();
        return;
      }
    }

    if (event.target.id === 'paypalEmail') {
      value = value.toLowerCase();
    }

    this.setState({ [event.target.id]: value });
  };

  handleResidenceChange = (event) => {
    const values = ['userIsInUk', 'userIsInEu', 'userIsInUs', 'userIsInternational', 'paypal'];
    const unselectedRadio = values.filter((value) => value !== event.target.value);

    this.setState({ [event.target.value]: true });

    unselectedRadio.forEach((element) => {
      this.setState({ [element]: false });
    });
  };

  handleSortCodeChange = (paymentSortCode) => {
    this.setState({ paymentSortCode });
    this.forceUpdate();
  };

  handleSendConfirmation() {
    this.sendInvoice();
  }

  sendInvoice() {
    const { user } = this.props;

    const {
      addressCounty,
      addressLine1,
      addressLine2,
      addressPostcode,
      addressTown,
      paypalAddress,
      paymentAccountNumber,
      paymentBank,
      paymentName,
      paymentSortCode,
      paypalEmail,
      userIsInUk,
      paypal,
      userIsInEu,
      userIsInUs,
      userIsInternational,
      bic,
      iban,
      routing,
      bankNumber,
      branchCode
    } = this.state;

    this.setState({ errorMessage: null, isLoading: true });
    this.buttonModal.close();

    const handleError = (error) => {
      const msg = error.response && error.response.data ? error.response.data : error;
      this.setState({ errorMessage: msg, isLoading: false });
    };

    const handleSuccess = () => {
      window.scrollTo(0, 0);
      this.setState({ invoiceWasSent: true, isLoading: false });
    };

    let data;

    if (userIsInUk) {
      data = getSnakeCaseObject({
        addressCounty,
        addressLine1,
        addressLine2,
        addressPostcode,
        addressTown,
        paymentAccountNumber,
        paymentBank,
        paymentName,
        paymentSortCode
      });
      data.payment_destiny = 'uk';
    } else if (userIsInEu) {
      data = getSnakeCaseObject({
        addressCounty,
        addressLine1,
        addressLine2,
        addressPostcode,
        addressTown,
        paymentBank,
        paymentName,
        iban,
        bic
      });
      data.payment_destiny = 'eu';
    } else if (userIsInUs) {
      data = getSnakeCaseObject({
        addressCounty,
        addressLine1,
        addressLine2,
        addressPostcode,
        addressTown,
        paymentBank,
        paymentName,
        routing,
        bankNumber
      });
      data.payment_destiny = 'us';
    } else if (userIsInternational) {
      data = getSnakeCaseObject({
        addressCounty,
        addressLine1,
        addressLine2,
        addressPostcode,
        addressTown,
        paymentBank,
        paymentName,
        branchCode,
        bankNumber,
        bic
      });
      data.payment_destiny = 'international';
    } else if (paypal) {
      data = getSnakeCaseObject({ paypalAddress, paymentName, paypalEmail });
      data.payment_destiny = 'paypal';
    }

    this.props.createInvoice(user.token, data, handleError, handleSuccess);
  }

  validateAccountNumber() {
    return this.state.paymentAccountNumber.match(/^\d{7}\d?$/);
  }

  validateInput() {
    if (this.state.userIsInUk) {
      return !!(
        this.state.addressLine1.length &&
        this.state.addressTown.length &&
        (this.state.addressCounty.length || this.validatePostcode()) &&
        this.state.paymentName.length &&
        this.state.paymentBank.length &&
        this.validateAccountNumber() &&
        this.validateSortCode()
      );
    } else if (this.state.userIsInEu) {
      return !!(
        this.state.addressLine1.length &&
        this.state.addressTown.length &&
        (this.state.addressCounty.length || this.state.addressPostcode.length) &&
        this.state.paymentName.length &&
        this.state.paymentBank.length &&
        this.validateIban() &&
        this.validateBic()
      );
    } else if (this.state.userIsInUs) {
      return !!(
        this.state.addressLine1.length &&
        this.state.addressTown.length &&
        (this.state.addressCounty.length || this.state.addressPostcode.length) &&
        this.state.paymentName.length &&
        this.state.paymentBank.length &&
        this.validateBankNumber() &&
        this.validateRouting()
      );
    } else if (this.state.userIsInternational) {
      return !!(
        this.state.addressLine1.length &&
        this.state.addressTown.length &&
        (this.state.addressCounty.length || this.state.addressPostcode.length) &&
        this.state.paymentName.length &&
        this.state.paymentBank.length &&
        (this.validateBic() || this.validateSwift()) &&
        this.validateBranchCode() &&
        this.validateBankNumber()
      );
    } else if (this.state.paypal) {
      return !!(this.state.paypalAddress.length && this.validatePaypalEmail());
    }
  }

  validatePaypalEmail() {
    return EMAIL_REG_EXP.test(this.state.paypalEmail);
  }

  validatePostcode() {
    return this.state.addressPostcode.match(POSTCODE_REG_EXP);
  }

  validateIban() {
    const ibanStripped = this.state.iban
      .replace(/[^A-Z0-9]+/gi, '') // keep numbers and letters only
      .toUpperCase(); // calculation expects upper-case
    const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
    if (!m) return false;

    const numbericed = (m[3] + m[1] + m[2]).replace(
      /[A-Z]/g,
      (ch) =>
        // replace upper-case characters by numbers 10 to 35
        ch.charCodeAt(0) - 55
    );
    // The resulting number would be to long for javascript to handle without loosing precision.
    // So the trick is to chop the string up in smaller parts.
    const mod97 = numbericed.match(/\d{1,7}/g).reduce((total, curr) => Number(total + curr) % 97, '');

    return mod97 === 1;
  }

  validateBic() {
    return this.state.bic.match(
      /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/
    );
  }

  validateSwift() {
    return this.state.bic !== '';
  }

  validateBankNumber() {
    return this.state.bankNumber !== '';
  }

  validateRouting() {
    return this.state.routing !== '';
  }

  validateBranchCode() {
    return this.state.branchCode !== '';
  }

  validateSortCode() {
    return this.state.paymentSortCode.match(/^\d\d-\d\d-\d\d$/);
  }

  render() {
    const { errorMessage, invoiceWasSent, isLoading, userIsInUk, userIsInEu, userIsInUs, userIsInternational, paypal } = this.state;

    const { appointments, invoices, timezone } = this.props;

    const sortedAppointments = Array.isArray(appointments)
      ? appointments.sort((a, b) => {
          // Compare dates first
          const dateComparison = a.date.localeCompare(b.date);

          // If the dates are the same, compare start times
          if (dateComparison === 0) {
            return a.start_time.localeCompare(b.start_time);
          }

          return dateComparison;
        })
      : [];

    const apptsMissingChatCodes = sortedAppointments.filter((appt) => appt.status === 'booked' && appt.need_chat_code === true);

    const payableAppts = sortedAppointments.filter((appt) => appt.status !== 'booked');

    const now = moment.utc();

    const lastInvoicedPeriodStart = this.getLastInvoicedPeriodStart(invoices);
    const invoicingIsAllowedToday = this.checkIfInvoicingAllowedToday(now, lastInvoicedPeriodStart);

    const nextAllowedInvoicingMoment = now.clone();
    if (!invoicingIsAllowedToday) {
      nextAllowedInvoicingMoment.date(1);
      nextAllowedInvoicingMoment.add(1, 'month');
    }

    const userCanInvoice = invoicingIsAllowedToday && payableAppts.length > 0;

    const nextAllowedInvoicingMonthName = nextAllowedInvoicingMoment.format('MMMM');
    const nextAllowedInvoicingYearString = nextAllowedInvoicingMoment.format('YYYY');

    if (invoiceWasSent)
      return (
        <div className={styles.container}>
          <h2 className={styles.title}>Invoice sent!</h2>
          <div className={styles['invoice-generated--wrapper']}>
            <p className={styles.paragraph}>
              Thank you &ndash; your invoice for {nextAllowedInvoicingMonthName} was sent to Chatterbox. A copy was also sent to your inbox.
              <strong> You do not need to do anything else for this invoice.</strong>
            </p>
            <ul className={styles['invoice-timeline--wrapper']}>
              <li>
                <NumberIcon number={1} />
                <u>
                  {now.date()} {now.format('MMMM')}, {now.format('YYYY')}
                </u>
                : Invoice generated and received by Chatterbox <img src={IconCheck} alt="" className={styles['check-icon']} />
              </li>

              <li>
                <NumberIcon number={2} />
                <u>
                  18&ndash;20 {now.format('MMMM')}, {now.format('YYYY')}
                </u>
                : Invoice will be reviewed by Chatterbox
              </li>

              <li>
                <NumberIcon number={3} />
                <u>
                  21&ndash;25 {now.format('MMMM')}, {now.format('YYYY')}
                </u>
                : Payment should be expected.{' '}
                <em>If you do not receive your payment or hear from us by 26 {now.format('MMMM')}, please contact us at community@chatterbox.io</em>
              </li>
            </ul>
            <div className={styles['go-back']}>
              <Link to="/">Go back to Dashboard</Link>
            </div>
          </div>
        </div>
      );

    return (
      <div className={[styles.container, this.state.isLoading ? appStyles.loading : null].join(' ')}>
        <h2 className={styles.title}>{Invoices}</h2>
        <div className={styles['invoice-not-generated--wrapper']}>
          <p>
            Please send your next invoice between {ALLOWED_INVOICING_START_DAY_OF_MONTH} {nextAllowedInvoicingMonthName}{' '}
            {nextAllowedInvoicingYearString} and {ALLOWED_INVOICING_END_DAY_OF_MONTH} {nextAllowedInvoicingMonthName} {nextAllowedInvoicingYearString}
            .
          </p>
          <div className={styles['check-step']}>
            <p className={styles.paragraph}>
              <NumberIcon number={1} />
              Please check that everything is correct:
            </p>
            <InvoiceTable appointments={payableAppts} timeZone={timezone} withTotals="true" />
            <p className={styles['contact-us']}>
              Is anything not correct? Please <a href="mailto:community@chatterbox.io">email us</a>.
            </p>
          </div>

          {apptsMissingChatCodes.length > 0 && (
            <div className={styles['warning--wrapper']}>
              <p className={styles['warning--message']}>
                <img src={IconWarning} alt="" />
                &nbsp;&nbsp;You are missing chat codes for the following Live Practices. To include them in this invoice, please enter the codes.
              </p>

              <InvoiceTable appointments={apptsMissingChatCodes} timeZone={timezone} />

              <div className={styles['button--wrapper']}>
                <Link to="/chat-code?invoice=1">
                  <Button>Add chat codes</Button>
                </Link>
              </div>
            </div>
          )}

          <div className={`${styles.newSection} ${userCanInvoice ? '' : styles.disabledBlock}`}>
            {!userCanInvoice && (
              <div className={styles.disabledMessageContainer}>
                {!invoicingIsAllowedToday ? (
                  <p className={styles.disabledMessage}>
                    You will be able to create and send your next invoice between {ALLOWED_INVOICING_START_DAY_OF_MONTH}{' '}
                    {nextAllowedInvoicingMonthName} and {ALLOWED_INVOICING_END_DAY_OF_MONTH} {nextAllowedInvoicingMonthName}.<br /> Please come back
                    later. Thank you.
                  </p>
                ) : (
                  payableAppts.length <= 0 && (
                    <p className={styles.disabledMessage}>
                      You have no payable appointments.
                      <br /> Please come back later. Thank you.
                    </p>
                  )
                )}
              </div>
            )}
            <div className={styles['check-step']}>
              <p className={styles.paragraph}>
                <NumberIcon number={2} />
                Please enter your personal details. You will be asked to enter them for every invoice for data security purposes.
              </p>

              <form className={styles.countrySelector} inline onSubmit={this.doNothing}>
                <fieldset className={styles['form-group']}>
                  <h3 className={styles['form-group--title']}>Where would you like to be paid?</h3>
                  <div className={styles['options']}>
                    <div className={styles['option']}>
                      <input
                        type="radio"
                        id="UK"
                        checked={userIsInUk}
                        inline
                        name="userIsInUk"
                        onChange={this.handleResidenceChange}
                        value="userIsInUk"
                      />
                      <label htmlFor="UK">UK Bank Account (British Pound)</label>
                    </div>
                    <div className={styles['option']}>
                      <input
                        type="radio"
                        id="EU"
                        checked={userIsInEu}
                        inline
                        name="userIsInEu"
                        onChange={this.handleResidenceChange}
                        value="userIsInEu"
                      />
                      <label htmlFor="EU">European bank account</label>
                    </div>
                    <div className={styles['option']}>
                      <input
                        type="radio"
                        id="US"
                        checked={userIsInUs}
                        inline
                        name="userIsInUs"
                        onChange={this.handleResidenceChange}
                        value="userIsInUs"
                      />
                      <label htmlFor="US">US bank account</label>
                    </div>
                    <div className={styles['option']}>
                      <input
                        type="radio"
                        id="international"
                        checked={userIsInternational}
                        inline
                        name="userIsInternational"
                        onChange={this.handleResidenceChange}
                        value="userIsInternational"
                      />
                      <label htmlFor="international">International bank account</label>
                    </div>
                    <div className={styles['option']}>
                      <input type="radio" id="paypal" checked={paypal} inline name="paypal" onChange={this.handleResidenceChange} value="paypal" />
                      <label htmlFor="paypal">Paypal</label>
                    </div>
                  </div>
                </fieldset>
              </form>
              {(userIsInEu || userIsInUs || userIsInternational) && (
                <p className={styles.notice}>
                  You will receive your total invoice converted in your currency, corresponding to that day’s currency exchange by your bank. Bank
                  fees may apply.
                </p>
              )}
              {paypal && <p className={styles.notice}>Paypal may incur a fee (2.9% - 5%). Bank account transfers are preferable.</p>}
            </div>

            <form onSubmit={this.doNothing}>
              <div className={styles['form-group']}>
                <h3 className={styles['form-group--title']}>
                  Address <span className={styles.required}>*</span>
                </h3>

                {(userIsInUk || userIsInEu || userIsInUs || userIsInternational) && (
                  <div className={styles['form-fields']}>
                    <fieldset>
                      <label htmlFor="addressLine1">Building and street</label>

                      <input
                        className={[styles.textInput, styles['first-of-two'], styles['building']].join(' ')}
                        id="addressLine1"
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.addressLine1}
                      />

                      <input
                        className={styles.textInput}
                        id="addressLine2"
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.addressLine2}
                      />
                    </fieldset>

                    <fieldset>
                      <label>Town or city</label>
                      <input
                        className={styles.textInput}
                        id="addressTown"
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.addressTown}
                      />
                    </fieldset>

                    <fieldset>
                      <label>County</label>
                      <input
                        className={styles.textInput}
                        id="addressCounty"
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.addressCounty}
                      />
                    </fieldset>

                    <fieldset>
                      <label>Postcode</label>
                      <input
                        className={styles.textInput}
                        id="addressPostcode"
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        placeholder={userIsInUk && 'AA99 9AA'}
                        type="text"
                        value={this.state.addressPostcode}
                      />
                      {this.state.addressPostcode && userIsInUk && !this.isFocused('addressPostcode') && !this.validatePostcode() && (
                        <p className={styles.fieldError}>Must be in format AA99 9AA</p>
                      )}
                    </fieldset>
                  </div>
                )}

                {paypal && (
                  <textarea
                    className={styles.textBox}
                    componentClass="textarea"
                    id="paypalAddress"
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    value={this.state.paypalAddress}
                  />
                )}
              </div>

              <div className={styles['form-group']}>
                <h3 className={styles['form-group--title']}>
                  Payment details <span className={styles.required}>*</span>
                </h3>
                <div className={styles['form-fields']}>
                  <fieldset>
                    <label htmlFor="paymentName">Your full name</label>
                    <input
                      className={styles.textInput}
                      id="paymentName"
                      onBlur={this.onBlur}
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.paymentName}
                    />
                  </fieldset>

                  {(userIsInUk || userIsInEu || userIsInUs || userIsInternational) && (
                    <fieldset>
                      <label>Bank name</label>
                      <input
                        className={styles.textInput}
                        id="paymentBank"
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.paymentBank}
                      />
                    </fieldset>
                  )}

                  {userIsInEu && (
                    <>
                      <fieldset>
                        <label>IBAN</label>
                        <input
                          className={styles.textInput}
                          id="iban"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          type="text"
                          value={this.state.iban}
                        />
                        {this.state.iban && !this.isFocused('iban') && !this.validateIban() && <p className={styles.fieldError}>Invalid IBAN</p>}
                      </fieldset>

                      <fieldset>
                        <label>BIC</label>
                        <input
                          className={styles.textInput}
                          id="bic"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          type="text"
                          value={this.state.bic}
                        />
                        {this.state.bic && !this.isFocused('bic') && !this.validateBic() && <p className={styles.fieldError}>Invalid BIC</p>}
                      </fieldset>
                    </>
                  )}

                  {userIsInUs && (
                    <>
                      <fieldset>
                        <label>Routing number (ABA or RTN)</label>
                        <input
                          className={styles.textInput}
                          id="routing"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          type="text"
                          value={this.state.routing}
                        />
                        {this.state.routing && !this.isFocused('routing') && !this.validateRouting() && (
                          <p className={styles.fieldError}>Invalid Routing Number</p>
                        )}
                      </fieldset>

                      <fieldset>
                        <label>Bank account number</label>
                        <input
                          className={styles.textInput}
                          id="bankNumber"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          type="text"
                          value={this.state.bankNumber}
                        />
                        {this.state.bic && !this.isFocused('bankNumber') && !this.validateBankNumber() && (
                          <p className={styles.fieldError}>Invalid Bank Account Number</p>
                        )}
                      </fieldset>
                    </>
                  )}

                  {userIsInternational && (
                    <>
                      <fieldset>
                        <label>SWIFT/BIC</label>
                        <input
                          className={styles.textInput}
                          id="bic"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          type="text"
                          value={this.state.bic}
                        />
                        {this.state.bic && !this.isFocused('bic') && !(this.validateBic() || this.validateSwift()) && (
                          <p className={styles.fieldError}>Invalid SWIFT/BIC</p>
                        )}
                      </fieldset>

                      <fieldset>
                        <label>Branch code</label>
                        <input
                          className={styles.textInput}
                          id="branchCode"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          type="text"
                          value={this.state.branchCode}
                        />
                        {this.state.bic && !this.isFocused('branchCode') && !this.validateBranchCode() && (
                          <p className={styles.fieldError}>Invalid branch code</p>
                        )}
                      </fieldset>

                      <fieldset>
                        <label>Bank account number</label>
                        <input
                          className={styles.textInput}
                          id="bankNumber"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          type="text"
                          value={this.state.bankNumber}
                        />
                        {this.state.bic && !this.isFocused('bankNumber') && !this.validateBankNumber() && (
                          <p className={styles.fieldError}>Invalid Bank Account Number</p>
                        )}
                      </fieldset>
                    </>
                  )}

                  {userIsInUk && (
                    <>
                      <fieldset>
                        <label>Account number</label>
                        <input
                          className={styles.textInput}
                          id="paymentAccountNumber"
                          max="99999999"
                          maxLength="8"
                          min="0"
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          placeholder="12345678"
                          value={this.state.paymentAccountNumber}
                        />
                        {this.state.paymentAccountNumber && !this.isFocused('paymentAccountNumber') && !this.validateAccountNumber() && (
                          <p className={styles.fieldError}>Must be seven or eight digits</p>
                        )}
                      </fieldset>

                      <fieldset className="sortCodeGroup">
                        <label>Sort code</label>
                        <SortCodeInput
                          onBlur={this.onBlur}
                          onChange={this.handleSortCodeChange}
                          onFocus={this.onSortCodeFocus}
                          value={this.state.paymentSortCode}
                        />

                        {this.state.paymentSortCode && !this.sortCodeIsFocused() && !this.validateSortCode() && (
                          <p className={styles.fieldError}>Must have six digits</p>
                        )}
                      </fieldset>
                    </>
                  )}

                  {paypal && (
                    <fieldset>
                      <label>PayPal email address</label>
                      <input
                        className={styles.textInput}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        id="paypalEmail"
                        type="text"
                        value={this.state.paypalEmail}
                      />
                      {this.state.paypalEmail && !this.isFocused('paypalEmail') && !this.validatePaypalEmail() && (
                        <p className={styles.fieldError}>Not a valid email address</p>
                      )}
                    </fieldset>
                  )}
                </div>
              </div>
            </form>
            <ErrorMessage errorMsg={errorMessage} show={errorMessage} />

            <div className={`${errorMessage ? '' : styles.newSection} ${styles.lastSection}`}>
              <div className={[styles['check-step'], styles.singleItem].join(' ')}>
                <NumberIcon number={3} />

                <div>
                  <ButtonModal
                    buttonStyle="primary"
                    buttonText="Send invoice"
                    closeAlert={() => {}}
                    disabled={!this.validateInput() || isLoading}
                    ref={(buttonModal) => {
                      this.buttonModal = buttonModal;
                    }}
                    withCloseButton={false}>
                    <div>
                      <h3 className={styles.modalHeading}>Are you sure?</h3>

                      <p className={styles.paragraph}>
                        Once you generate your invoice and send it to Chatterbox, you <u>cannot</u> go back and edit it.
                      </p>

                      <div className={styles.modalActions}>
                        <Button bsStyle="primary" onClick={() => this.handleSendConfirmation()}>
                          Yes, send it
                        </Button>

                        <Button bsStyle="link" onClick={() => this.buttonModal.close()}>
                          No, go back
                        </Button>
                      </div>
                    </div>
                  </ButtonModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Invoices.propTypes = {
  appointments: PropTypes.array.isRequired,
  createInvoice: PropTypes.func.isRequired,
  getInvoiceMonthAppointments: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  resetAppointments: PropTypes.func.isRequired,
  invoices: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    appointments: state.appointments || [],
    invoices: state.invoices || [],
    user: state.user,
    timezone: state.time.timezone
  };
}

export default connect(mapStateToProps, {
  createInvoice,
  getInvoiceMonthAppointments,
  getInvoices,
  resetAppointments
})(Invoices);
