import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from '../Row.module.scss';

import Button from '../../design-system/button/Button';
import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../../../utils/time-helpers';
import Dropdown from '../../design-system/dropdown/Dropdown';
import { registerTutorAttendance } from '../../../actions/appointments';
import {
  getPastAppointmentStatusLabelFromValue,
  parseHappenedToAttended,
  pastAppointmentStatus,
  tutorAttendanceStatus
} from '../../../domains/appointments/status';
import { add, isAfter, isWithinInterval, sub } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { videoPlatformsKeys } from '../../../domains/user/video-platforms';
import { capitalize } from '../../../utils/string-helpers';
import { getCourseLevelFromName } from '../../../domains/course/course';
import JoinTeams from '../../informative-modals/JoinTeams/join-teams';
import ConfirmStatusChange from '../ConfirmStatusChange';
import { changeAppointmentStatus } from '../../../actions/appointments';
import { appointmentStatus } from '../../../domains/appointments/status';

const Row = ({
  appointment,
  changeAppointmentStatusAction,
  isChangingAppointmentStatus,
  isRegisteringTutorAttendance,
  registerTutorAttendanceAction,
  user
}) => {
  const [status, setStatus] = useState(appointment?.status);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showTeamsModal, setShowTeamsModal] = useState(false);
  const [teamsEmail, setTeamsEmail] = useState('');

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getApptFullDate = () => {
    return convertStringsOfDateAndTimeToDate(appointment?.date, appointment?.start_time);
  };

  const getTimezoned = () => {
    const apptDate = getApptFullDate();
    return convertToLocaleAndFormat(apptDate, timezone, 'HH:mm');
  };

  const getStudentPreferredPlatform = () => {
    if (!appointment?.students?.length) return '';
    return appointment.students[0].preferred_class;
  };

  const getStudentTeamsEmail = () => {
    if (!appointment?.students?.length) return '';
    return appointment.students[0].teams_email ? appointment.students[0].teams_email : appointment.students[0].email;
  };

  const getPreferredPlatform = () => {
    const preferredPlatform = getStudentPreferredPlatform(appointment);
    return {
      preferredPlatform,
      link: preferredPlatform === 'teams' ? getStudentTeamsEmail() : user[videoPlatformsKeys[preferredPlatform]]
    };
  };

  const getJoinButton = () => {
    const timezoned = utcToZonedTime(getApptFullDate(), timezone);
    const { preferredPlatform } = getPreferredPlatform();

    if (appointment?.status === appointmentStatus.canceled) return <p className={styles['status-cancelled']}>Cancelled</p>;

    if (appointment?.status === appointmentStatus.canceledLate) return <p className={styles['status-cancelled']}>Cancelled Late</p>;

    if (
      !isWithinInterval(new Date(), {
        start: sub(timezoned, { minutes: 15 }),
        end: add(timezoned, { minutes: appointment.duration })
      })
    )
      return <p className={styles['status-blocked']}>{capitalize(preferredPlatform)}</p>;

    return (
      <div className={styles['join-wrapper']}>
        <Button outfit="greenButton" onClick={handleJoin} loading={isRegisteringTutorAttendance}>
          Join {capitalize(preferredPlatform)}
        </Button>
      </div>
    );
  };

  const getTutorAttendance = () => {
    if (tutorAttendanceStatus[appointment?.tutor_attendance]) return tutorAttendanceStatus[appointment?.tutor_attendance];
    if (appointment?.tutor_attendance) return appointment?.tutor_attendance;
    return '';
  };

  const getStudentFullName = () => {
    return `${appointment?.students[0]?.first_name} ${appointment?.students[0]?.last_name}`;
  };

  const removeVersionFromCourseName = (courseName) => {
    // Remove " 2.0" from the end of the string using replace
    let cleanedCourseName = courseName.replace(' 2.0', '');

    // Trim any trailing spaces from the string
    cleanedCourseName = cleanedCourseName.trim();

    return cleanedCourseName;
  };

  const getNameToBeDisplayed = (appt) => {
    if (!appt) return '';
    if ((appt?.appointment_type_name?.toLowerCase() || '').includes('introductory class')) return 'Meet Your Coach';
    if ((appt?.appointment_type_name?.toLowerCase() || '').includes('conversation'))
      return `${removeVersionFromCourseName(appointment?.course_name)} - Convo Practice`;
    return `${getCourseLevelFromName(appointment?.course_name)}: Lesson ${appointment?.lesson_number}`;
  };

  const getResourceLink = () => {
    const nameDisplayed = getNameToBeDisplayed(appointment);

    if (appointment?.resource_url) {
      if (appointment?.resource_url.startsWith('http')) {
        return (
          <a href={`${appointment?.resource_url}`} target="_blank" rel="noreferrer noopener">
            {nameDisplayed}
          </a>
        );
      } else {
        return (
          <Link to={`${appointment?.resource_url}`} target="_blank" rel="noreferrer noopener">
            {nameDisplayed}
          </Link>
        );
      }
    }

    return (
      <Link to={`/resources/${appointment?.course_id}/${appointment?.lesson_number}`} target="_blank" rel="noreferrer noopener">
        {nameDisplayed}
      </Link>
    );
  };

  const getStudentOutcome = () => {
    const timezonedAppt = utcToZonedTime(getApptFullDate(), timezone);
    const endOfAppointment = add(timezonedAppt, { minutes: appointment.duration });
    const timezonedNow = new Date();
    if (isAfter(endOfAppointment, timezonedNow)) return null;

    const parsedStatus = parseHappenedToAttended(status || appointment?.status);

    if (appointment?.outcome_assigned) return <p>{getPastAppointmentStatusLabelFromValue(parsedStatus)}</p>;

    return (
      <Dropdown
        key={`${appointment?.id}-${parsedStatus}`}
        defaultValue={parsedStatus}
        onSelect={handleSelection}
        options={[
          { label: pastAppointmentStatus.studentAttended.label, value: pastAppointmentStatus.studentAttended.value },
          { label: pastAppointmentStatus.studentNoShow.label, value: pastAppointmentStatus.studentNoShow.value },
          { label: pastAppointmentStatus.technicalIssues.label, value: pastAppointmentStatus.technicalIssues.value }
        ]}
      />
    );
  };

  const handleJoin = () => {
    const { preferredPlatform, link } = getPreferredPlatform();

    if (preferredPlatform === 'teams') {
      setTeamsEmail(link);
      setShowTeamsModal(true);
    } else {
      window.open(link, '_blank', 'noopener,noreferrer');
    }

    registerTutorAttendanceAction({ token: user?.token, appointmentId: appointment?.id });
  };

  const changeAppointmentStatus = (message) => {
    changeAppointmentStatusAction({
      token: user?.token,
      appointmentId: appointment?.id,
      status,
      message,
      successCallback: () => setShowConfirmationModal(false)
    });
  };

  const handleSelection = (st) => {
    setStatus(st);
    setShowConfirmationModal(true);
  };

  const onCloseConfirmationModal = () => {
    setStatus('');
    setShowConfirmationModal(false);
  };

  return (
    <tr className={styles.row}>
      <td>
        <p>{getTimezoned()}</p>
      </td>
      <td>
        <p>{getJoinButton()}</p>
      </td>
      <td>
        <p>{getStudentFullName()}</p>
      </td>
      <td>{getResourceLink()}</td>
      <td>
        <p>{getTutorAttendance()}</p>
      </td>
      <td>{getStudentOutcome()}</td>
      {showTeamsModal && <JoinTeams onClose={() => setShowTeamsModal(false)} teamsEmail={teamsEmail} />}
      {showConfirmationModal && (
        <ConfirmStatusChange
          onConfirm={changeAppointmentStatus}
          onClose={onCloseConfirmationModal}
          status={status}
          isLoading={isChangingAppointmentStatus}
        />
      )}
    </tr>
  );
};

Row.propTypes = {
  appointment: PropTypes.object.isRequired,
  changeAppointmentStatusAction: PropTypes.func.isRequired,
  errorChangingAppointmentStatus: PropTypes.bool,
  isChangingAppointmentStatus: PropTypes.bool,
  isChangingAppointmentStatusSuccess: PropTypes.bool,
  isRegisteringTutorAttendance: PropTypes.bool,
  registerTutorAttendanceAction: PropTypes.func.isRequired,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  isChangingAppointmentStatus: state.appointments.isChangingAppointmentStatus,
  isRegisteringTutorAttendance: state.appointments.isRegisteringTutorAttendance
});

export default connect(mapStateToProps, {
  changeAppointmentStatusAction: changeAppointmentStatus,
  registerTutorAttendanceAction: registerTutorAttendance
})(Row);
