import mixpanel from 'mixpanel-browser';
import { isInternalUser } from './url-helpers';

const mixpanelHelper = {
  identifyUser: ({
    id,
    email,
    company,
    language,
    courseLevel,
    typeOfJob,
    livePracticeCount = 0,
    numberOfCoaches = 0,
    ceregoProgress = 0,
    numberOfMsgsToCoach = 0,
    numberOfMsgsToLH = 0
  }) => {
    console.log('identifyUser');
    if (!mixpanel) return;
    if (!id || !email) return;

    mixpanel.identify(id);
    mixpanel.people.set({
      id,
      email,
      company: company && company[0]?.name,
      language,
      courseLevel: courseLevel && courseLevel[0],
      typeOfJob,
      livePracticeCount,
      numberOfCoaches,
      ceregoProgress,
      numberOfMsgsToCoach,
      numberOfMsgsToLH
    });
    if (isInternalUser(email)) mixpanel.opt_out_tracking();
  },
  visitedPage: (pageName, props) => {
    if (!mixpanel) return;
    mixpanel.track(`Visited ${pageName} page`, props);
  },
  openedModal: (modalName, props) => {
    if (!mixpanel) return;
    mixpanel.track(`Opened ${modalName} modal`, props);
  },
  closedModal: (modalName, props) => {
    if (!mixpanel) return;
    mixpanel.track(`Closed ${modalName} modal`, props);
  },
  showedSnackbar: (snackbarName, props) => {
    if (!mixpanel) return;
    mixpanel.track(`Showed ${snackbarName} snackbar`, props);
  },
  closedSnackbar: (snackbarName, props) => {
    if (!mixpanel) return;
    mixpanel.track(`Closed ${snackbarName} snackbar`, props);
  },
  recordElapsedTime: (component, timeSpent) => {
    if (!mixpanel) return;
    mixpanel.track(`Record time spent on ${component}`, { timeSpent });
  },
  lessonAction: (action, { ...rest }) => {
    if (!mixpanel) return;
    mixpanel.track(`${action} lesson(s)`, { ...rest });
  },
  postError: (errorMessage) => {
    if (!mixpanel) return;
    mixpanel.track(`Error: ${errorMessage}`);
  },
  click: (buttonName) => {
    if (!mixpanel) return;
    mixpanel.track(`Clicked ${buttonName}`);
  },
  identify: (id) => {
    if (!mixpanel) return;
    mixpanel.identify(id);
  },
  alias: (id) => {
    if (!mixpanel) return;
    mixpanel.alias(id);
  },
  track: (name, props) => {
    if (!mixpanel) return;
    mixpanel.track(name, props);
  },
  opt_out_tracking: () => {
    if (!mixpanel) return;
    mixpanel.opt_out_tracking();
  },
  opt_in_tracking: () => {
    if (!mixpanel) return;
    mixpanel.opt_in_tracking();
  },
  people: {
    set: (props) => {
      if (!mixpanel) return;
      mixpanel.people.set(props);
    },
    track_charge: (val) => {
      if (!mixpanel) return;
      mixpanel.people.track_charge(val);
    }
  }
};

export default mixpanelHelper;
